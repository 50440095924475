import { useElectionTellingStations } from 'api'
import { PopupProps } from 'components/molecules/Popup'
import { hooks } from 'hooks'
import { useNavigate } from 'react-router-dom'

const MIN_NUMBER_OF_TELLERS = 2

export const useOnStandardFlowSelect = (
    showPopup: (props: PopupProps) => void,
    hidePopup: () => void,
    electionId: string
) => {
    const navigate = useNavigate()
    const { data: stations } = useElectionTellingStations(electionId!, !!electionId)
    const { openLaunchStationPopup } =
        hooks.electionProcessing.stationLauncher.useLaunchStationPopup(electionId, showPopup, hidePopup)

    const stationsWithMinAcceptableTellers =
        stations?.filter(station => station.tellers.length >= MIN_NUMBER_OF_TELLERS)

    const onStandardFlowSelect = () => {
        if (stationsWithMinAcceptableTellers?.length) {
            openLaunchStationPopup()
        }
        else {
            navigate(`/election-stations/${electionId}`)
        }
    }

    return { onStandardFlowSelect }

}