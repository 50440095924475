import { Col, Form, FormInstance, Row } from 'antd'
import Checkbox from 'components/atoms/Checkbox'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import SectionTitle from '../SectionTitle'
import style from './index.module.scss'

type GeneralSettingsProps = {
    form: FormInstance,
    enabledTitle: string
    enabledSubtitle?: ReactNode
    flowCheckboxOnly?: boolean
    hideByPassFlowConfig?: boolean
}

function GeneralSettings({ form, enabledTitle, enabledSubtitle, hideByPassFlowConfig, flowCheckboxOnly }
    : GeneralSettingsProps) {
    const { t } = useTranslation('configuration')

    return (
        <Row gutter={16}>
            <Col span={6}>
                <SectionTitle>{t('general_settings')}:</SectionTitle>
            </Col>
            {!flowCheckboxOnly && <Col span={6}>
                <Form.Item
                    name={'electionEnabled'}
                    className="no-padding"
                    valuePropName="checked"
                >
                    <Checkbox
                        className={style.checkbox}
                        onChange={(e) => {
                            form.setFieldsValue({ electionEnabled: e.target.checked })
                        }}
                    >
                        <div className={style.checkboxTitle}>{enabledTitle}</div>
                        {!!enabledSubtitle && <div className={style.checkboxSubtitle}>{enabledSubtitle}</div>}
                    </Checkbox>
                </Form.Item>
            </Col>}
            <Col span={6}>
                <Form.Item
                    name={'byPassFlowEnabled'}
                    className="no-padding"
                    valuePropName="checked"
                >
                    {!hideByPassFlowConfig && <Checkbox
                        className={style.checkbox}
                        onChange={(e) => {
                            form.setFieldsValue({ byPassFlowEnabled: e.target.checked })
                        }}
                    >
                        <div className={style.checkboxTitle}>{t('enable_disable_flow_with_online_ballots_only')}</div>
                        <div className={style.checkboxSubtitle}>
                            {t('if_unchecked_online_ballots_only_flow_is_not_available')}
                        </div>
                    </Checkbox>}
                </Form.Item>
            </Col>
        </Row>
    )
}

export default GeneralSettings