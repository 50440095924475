import { useAuth } from 'hooks'
import { Role } from 'models'
import { lazy } from 'react'
import { useNavigate } from 'react-router-dom'

const SandboxDashboard = lazy(() => import('features/tellingStationSandbox/features/dashboard/pages/SandboxDashboard'))
const TellingStationDashboard = lazy(() => import('features/tellingStation/pages/Dashboard'))

function DefaultRouter() {
    const { auth } = useAuth()
    const navigate = useNavigate()

    if (!auth?.id) {
        navigate('/sign-in', { replace: true })
    }
    switch (auth!.role) {
        case Role.TELLING_STATION:
            return <TellingStationDashboard />
        default:
            return <SandboxDashboard />
    }
}

export default DefaultRouter