import { $generateJSONFromSelectedNodes } from '@lexical/clipboard'
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
    $getSelectionStyleValueForProperty,
    $patchStyleText
} from '@lexical/selection'
import { mergeRegister } from '@lexical/utils'
import IconWrapper from 'components/atoms/IconWrapper'
import { ReactComponent as Bold } from 'icons/bold.svg'
import { ReactComponent as Italic } from 'icons/italic.svg'
import { ReactComponent as Link } from 'icons/link.svg'
import { ReactComponent as Strikethrough } from 'icons/strikethrough.svg'
import { ReactComponent as TextAlignCenter } from 'icons/text-align-center.svg'
import { ReactComponent as TextAlignLeft } from 'icons/text-align-left.svg'
import { ReactComponent as TextAlignRight } from 'icons/text-align-right.svg'
import { ReactComponent as TextJustify } from 'icons/text-justify.svg'
import { ReactComponent as Underline } from 'icons/underline.svg'
import { ReactComponent as Undo } from 'icons/undo.svg'
import {
    $getSelection,
    $isRangeSelection,
    BLUR_COMMAND,
    CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    COMMAND_PRIORITY_NORMAL,
    FORMAT_ELEMENT_COMMAND,
    FORMAT_TEXT_COMMAND,
    KEY_MODIFIER_COMMAND,
    REDO_COMMAND,
    SELECTION_CHANGE_COMMAND,
    UNDO_COMMAND
} from 'lexical'
import { ColorStyle, FontStyle } from 'models'
import { Dispatch, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getSelectedNode, sanitizeUrl } from 'utils'
import RichTextEditorFontStyles from '../RichTextEditorFontStyles'
import { fontStyleRules, getFontStyleByProps } from '../RichTextEditorFontStyles/font-style-rules'
import RichTextEditorTextColorPicker from '../RichTextEditorTextColorPicker'
import style from './index.module.scss'

const LowPriority = 1

function Divider() {
    return <div className={style.divider} />
}

function ExtraSmallDivider() {
    return <div className={style.extraSmallDivider} />
}

type RichTextEditorToolbarProps = {
    setIsLinkEditMode: Dispatch<boolean>
    containerClassName: string
    onFocusLose: () => void
    setIsValid: Dispatch<boolean>
    required?: boolean
}

function RichTextEditorToolbar({ setIsLinkEditMode,
    containerClassName,
    onFocusLose,
    setIsValid,
    required
}: RichTextEditorToolbarProps) {
    const { t } = useTranslation()
    const [editor] = useLexicalComposerContext()
    const toolbarRef = useRef(null)
    const [canUndo, setCanUndo] = useState(false)
    const [canRedo, setCanRedo] = useState(false)
    const [isBold, setIsBold] = useState(false)
    const [isItalic, setIsItalic] = useState(false)
    const [isUnderline, setIsUnderline] = useState(false)
    const [isStrikethrough, setIsStrikethrough] = useState(false)
    const [isLink, setIsLink] = useState(false)
    const [fontColor, setFontColor] = useState<ColorStyle>(ColorStyle.Active)
    const [fontStyle, setFontStyle] = useState<FontStyle>(FontStyle.Title2)

    useEffect(() => {
        editor.update(
            () => {
                const selection = $getSelection()
                if (selection !== null) {
                    $patchStyleText(selection, { color: ColorStyle.Active })
                    const rules = fontStyleRules(FontStyle.Title2)
                    $patchStyleText(selection, {
                        'font-size': rules.fontSize,
                        'line-height': rules.lineHeight
                    })
                }
            }
        )
    }, [])

    const $updateToolbar = useCallback(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
            setIsBold(selection.hasFormat('bold'))
            setIsItalic(selection.hasFormat('italic'))
            setIsUnderline(selection.hasFormat('underline'))
            setIsStrikethrough(selection.hasFormat('strikethrough'))

            const node = getSelectedNode(selection)
            const parent = node.getParent()
            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true)
            } else {
                setIsLink(false)
            }

            setFontColor($getSelectionStyleValueForProperty(selection, 'color', ColorStyle.Active) as ColorStyle.Active)

            const defaultRules = fontStyleRules(FontStyle.Title2)
            const fontSize = $getSelectionStyleValueForProperty(selection, 'font-size', defaultRules.fontSize)
            const lineHeight = $getSelectionStyleValueForProperty(selection, 'line-height', defaultRules.lineHeight)

            setFontStyle(getFontStyleByProps(fontSize, lineHeight))
        }
    }, [])

    const isClickedOutside = useCallback((node: any): boolean => {
        if (!!node) {
            if (node.classList.contains(containerClassName)) {
                return false
            } else {
                return isClickedOutside(node.parentElement)
            }
        } else {
            return true
        }
    }, [containerClassName])

    const insertLink = useCallback(() => {
        if (!isLink) {
            //  setIsLinkEditMode(true)
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl('https://'))
        } else {
            setIsLinkEditMode(false)
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
        }
    }, [editor, isLink, setIsLinkEditMode])

    const validate = () => {
        const nodes = $generateJSONFromSelectedNodes(editor, null)
        let isValid = false
        nodes.nodes.forEach(node => {
            if (node.children?.length) {
                isValid = true
            }
        })

        if (required) {
            setIsValid(isValid)

            return isValid
        }

        setIsValid(true)

        return true
    }

    useEffect(() => editor.registerCommand(
        KEY_MODIFIER_COMMAND,
        (payload) => {
            const event: KeyboardEvent = payload
            const { code, ctrlKey, metaKey } = event

            if (code === 'KeyK' && (ctrlKey || metaKey)) {
                event.preventDefault()
                let url: string | null
                if (!isLink) {
                    setIsLinkEditMode(true)
                    url = sanitizeUrl('https://')
                } else {
                    setIsLinkEditMode(false)
                    url = null
                }

                return editor.dispatchCommand(TOGGLE_LINK_COMMAND, url)
            }

            return false
        },
        COMMAND_PRIORITY_NORMAL
    ), [editor, isLink, setIsLinkEditMode])

    useEffect(() => mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
            editorState.read(() => {
                $updateToolbar()
            })
        }),
        editor.registerCommand(
            SELECTION_CHANGE_COMMAND,
            (_payload, _newEditor) => {
                $updateToolbar()

                return false
            },
            LowPriority
        ),
        editor.registerCommand(
            CAN_UNDO_COMMAND,
            (payload) => {
                setCanUndo(payload)

                return false
            },
            LowPriority
        ),
        editor.registerCommand(
            CAN_REDO_COMMAND,
            (payload) => {
                setCanRedo(payload)

                return false
            },
            LowPriority
        ),
        editor.registerCommand(
            BLUR_COMMAND,
            (payload) => {
                if (isClickedOutside(payload.relatedTarget)) {
                    onFocusLose()
                    validate()
                }

                return false
            },
            LowPriority
        )
    ), [editor, $updateToolbar])

    const applyStyleText = useCallback(
        (styles: Record<string, string>) => {
            editor.update(
                () => {
                    const selection = $getSelection()
                    if (selection !== null) {
                        $patchStyleText(selection, styles)
                    }
                }
            )
        },
        [editor]
    )

    const onFontColorSelect = useCallback(
        (value: ColorStyle) => {
            setFontColor(value)
            applyStyleText({ color: value })
        },
        [applyStyleText]
    )

    return (
        <div className={style.toolbar} ref={toolbarRef}>
            <button
                disabled={!canUndo}
                type="button"
                onClick={() => {
                    editor.dispatchCommand(UNDO_COMMAND, undefined)
                }}
                className={`btn-main-tertiary-md ${style.button}`}
                aria-label={t('undo')}>
                <IconWrapper>
                    <Undo />
                </IconWrapper>
            </button>
            <ExtraSmallDivider />
            <button
                disabled={!canRedo}
                type="button"
                onClick={() => {
                    editor.dispatchCommand(REDO_COMMAND, undefined)
                }}
                className={`btn-main-tertiary-md ${style.button}`}
                aria-label={t('redo')}>
                <IconWrapper>
                    <Undo className="flip-horizontal" />
                </IconWrapper>
            </button>
            <Divider />
            <RichTextEditorFontStyles
                fontStyle={fontStyle}
                onChange={(fs) => setFontStyle(fs)}
                editor={editor}
            />
            <Divider />
            <button
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
                }}
                type="button"
                className={`btn-main-tertiary-md ${style.button} ${isBold ? style.active : ''}`}
                aria-label={t('format_bold')}>
                <IconWrapper>
                    <Bold />
                </IconWrapper>
            </button>
            <button
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
                }}
                type="button"
                className={`btn-main-tertiary-md ${style.button} ${isItalic ? style.active : ''}`}
                aria-label={t('format_italics')}>
                <IconWrapper>
                    <Italic />
                </IconWrapper>
            </button>
            <button
                type="button"
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
                }}
                className={`btn-main-tertiary-md ${style.button} ${isUnderline ? style.active : ''}`}
                aria-label={t('format_underline')}>
                <IconWrapper>
                    <Underline />
                </IconWrapper>
            </button>
            <button
                type="button"
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')
                }}
                className={`btn-main-tertiary-md ${style.button} ${isStrikethrough ? style.active : ''}`}
                aria-label={t('format_strikethrough')}>
                <IconWrapper>
                    <Strikethrough />
                </IconWrapper>
            </button>
            <button
                onClick={insertLink}
                className={`btn-main-tertiary-md ${style.button} ${isLink ? style.active : ''}`}
                aria-label="Insert link"
                type="button"
                title={t('insert_link')}
            >
                <IconWrapper>
                    <Link />
                </IconWrapper>
            </button>
            <button
                type="button"
                onClick={() => {
                    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left')
                }}
                className={`btn-main-tertiary-md ${style.button}`}
                aria-label={t('left_align')}>
                <IconWrapper>
                    <TextAlignLeft />
                </IconWrapper>
            </button>
            <button
                type="button"
                onClick={() => {
                    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center')
                }}
                className={`btn-main-tertiary-md ${style.button}`}
                aria-label={t('center_align')}>
                <IconWrapper>
                    <TextAlignCenter />
                </IconWrapper>
            </button>
            <button
                type="button"
                onClick={() => {
                    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right')
                }}
                className={`btn-main-tertiary-md ${style.button}`}
                aria-label={t('right_align')}>
                <IconWrapper>
                    <TextAlignRight />
                </IconWrapper>
            </button>
            <button
                type="button"
                onClick={() => {
                    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify')
                }}
                className={`btn-main-tertiary-md ${style.button}`}
                aria-label={t('justify_align')}>
                <IconWrapper>
                    <TextJustify />
                </IconWrapper>
            </button>
            <Divider />

            <RichTextEditorTextColorPicker color={fontColor} onChange={onFontColorSelect} />
        </div>
    )
}

export default RichTextEditorToolbar