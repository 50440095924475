import PageNotFound from 'components/organisms/PageNotFound'
import RoleBasedLayout from 'components/organisms/RoleBasedLayout'
import { Role } from 'models'
import { lazy } from 'react'
import { createBrowserRouter, redirect } from 'react-router-dom'
import RequireAuth from 'routing/RequireAuth'
import AdaptiveLayout from '../components/templates/AdaptiveLayout'
import Layout from '../components/templates/Layout'
import DefaultLayout from './DefaultLayout'
import DefaultRouter from './DefaultRouter'
import { errorHandlingRoutes } from './error-handling-routes'

const Configuration = lazy(() => import('features/administration/features/configuration/components/Configuration'))

const Auth = lazy(() => import('features/auth/pages/Auth'))
const SignIn = lazy(() => import('features/auth/pages/SignIn'))
const SaAuth = lazy(() => import('features/auth/pages/SaAuth'))
const Header = lazy(() => import('features/auth/components/common/Header'))
const SelectFunction = lazy(() => import('features/auth/pages/SelectFunction'))

const Balloting = lazy(() => import('features/onlineVoting/pages/Balloting'))

const Ballot = lazy(() => import('features/ballotingAssets/pages/Ballot'))

const RidvanElectionEditing = lazy(() => import('features/electionEditing/features/ridvan/pages/RidvanElectionEditing'))
const ByElectionEditing = lazy(() => import('features/electionEditing/features/byElection/pages/ByElectionEditing'))
const NationalDelegateEditing =
    lazy(() => import('features/electionEditing/features/national/pages/NationalDelegateEditing'))
const RbcEditing =
    lazy(() => import('features/electionEditing/features/rbcElection/pages/RbcEditing'))
const RbcElectionBoard =
    lazy(() => import('features/electionEditing/features/rbcElection/pages/RbcElectionBoard'))
const LocalDelegateElectionBoard =
    lazy(() => import('features/electionEditing/features/ridvan/pages/LocalDelegateElectionBoard'))
const ElectionTeam = lazy(() => import('features/electionEditing/pages/ElectionTeam'))

const ElectionBoard = lazy(() => import('features/electionProcessing/features/dashboard/pages/ElectionBoard'))
const LeaveFeedback = lazy(() => import('features/electionProcessing/pages/LeaveFeedback'))
const EligibleIndividuals =
    lazy(() => import('features/electionProcessing/features/eligibleIndividuals/pages/ViewEligibleIndividuals'))
const SetUpStations =
    lazy(() => import('features/electionProcessing/features/stationsManagement/pages/SetUpStations'))
const VotingStatistic =
    lazy(() => import('features/electionProcessing/features/votingStatistic/pages/ViewVotingStatistic'))

const ConfirmResults = lazy(() => import('features/electionResults/features/confirmation/pages/ConfirmResults'))
const ConfirmedResults = lazy(() => import('features/electionResults/features/confirmation/pages/ConfirmedResults'))
const MergeResults = lazy(() => import('features/electionResults/features/mergeResults/pages/MergeResults'))
const TiePhysicalBallots
    = lazy(() => import('features/electionResults/features/mergedResults/pages/TiePhysicalBallots'))
const MergedResults = lazy(() => import('features/electionResults/features/mergedResults/pages/MergedResultsWrapper'))
const RoundResultsInfo = lazy(() => import('features/electionResults/features/mergedResults/pages/RoundResultsInfo'))

const ManageOfficersElection = lazy(() => import('features/officersElection/features/manage/pages/Manage'))
const OfficersElection = lazy(() => import('features/officersElection/features/votingSession/pages/OfficersElection'))
const ViewElectionResults = lazy(() => import('features/officersElection/pages/ViewElectionResults'))

const MainElectionReport = lazy(() => import('features/reporting/pages/MainElectionReport'))
const Reporting = lazy(() => import('features/reporting/pages/Reporting'))
const ViewStationReport = lazy(() => import('features/reporting/pages/ViewStationReport'))

const ViewVoterVerification = lazy(() => import('features/tellingStation/pages/ViewVoterVerification'))

export const router = createBrowserRouter([
    {
        path: '/',
        element: <DefaultLayout />,
        children: [
            {
                index: true,
                element: <DefaultRouter />
            }
        ]
    },
    //Avoid 404 when old urls are used
    { path: '/community/voter/dashboard', loader: () => redirect('/') },
    { path: '/community/login', loader: () => redirect('/') },
    { path: '/community/auth', loader: () => redirect('/') },
    { path: '/user/password', loader: () => redirect('/') },
    { path: '/user/login', loader: () => redirect('/') },
    { path: '/user/password/reset', loader: () => redirect('/') },
    { path: '/obs-database', loader: () => redirect('/') },
    { path: '/obs-database/*', loader: () => redirect('/') },
    { path: '/system/admin-user/login', loader: () => redirect('/') },
    {
        element: <AdaptiveLayout header={<></>} />,
        children: [
            {
                path: '/auth',
                element: <Auth />
            },
            {
                path: '/sign-in',
                element: <SignIn />
            },
            {
                path: '/system',
                element: <SaAuth />
            }
        ]
    },
    {
        element: <AdaptiveLayout header={<Header />} />,
        children: [
            {
                path: '/function',
                element: <SelectFunction />
            }
        ]
    },
    errorHandlingRoutes,
    {
        element: <RequireAuth allowedRoles={[Role.VOTER]} />,
        children: [
            {
                element: <AdaptiveLayout />,
                children: [
                    {
                        path: '/balloting/:electionId',
                        element: <Balloting />
                    }
                ]
            }

        ]
    },
    {
        element: <RequireAuth allowedRoles={[Role.LSA_OFFICER]} />,
        children: [
            {
                element: <Layout />,
                children: [
                    {
                        path: '/ridvan/:electionPeriodId',
                        element: <RidvanElectionEditing />
                    },
                    {
                        path: '/by/:electionPeriodId',
                        element: <ByElectionEditing />
                    },
                    {
                        path: '/national-delegate/:electionPeriodId',
                        element: <NationalDelegateEditing />
                    },
                    {
                        path: '/officers-election',
                        element: <ManageOfficersElection />
                    }
                ]
            }
        ]
    },
    {
        element: <RequireAuth allowedRoles={[Role.LSA_OFFICER, Role.NSA_ADMIN, Role.SYSTEM_ADMIN]} />,
        children: [
            {
                element: <Layout />,
                children: [
                    {
                        path: '/election-board/:electionId',
                        element: <ElectionBoard />
                    },
                    {
                        path: '/confirm-results/:electionId',
                        element: <ConfirmResults />
                    },
                    {
                        path: '/local-delegate-election-board/:electionPeriodId',
                        element: <LocalDelegateElectionBoard />
                    }
                ]
            }
        ]
    },
    {
        element: <RequireAuth allowedRoles={[Role.CHIEF_TELLER_ASSISTANT, Role.CHIEF_TELLER]} />,
        children: [
            {
                element: <Layout />,
                children: [
                    {
                        path: '/merge-results',
                        element: <MergeResults />
                    },
                    {
                        path: '/report/:electionId',
                        element: <MainElectionReport />
                    },
                    {
                        path: '/tie-physical-ballots/:electionId',
                        element: <TiePhysicalBallots />
                    },
                    {
                        path: '/feedback',
                        element: <LeaveFeedback />
                    }
                ]
            }
        ]
    },
    {
        element: <RequireAuth allowedRoles={[Role.CHIEF_TELLER_ASSISTANT,
        Role.CHIEF_TELLER,
        Role.LSA_OFFICER,
        Role.NSA_ADMIN,
        Role.SYSTEM_ADMIN]} />,
        children: [
            {
                element: <Layout />,
                children: [
                    {
                        path: '/merged-results/:electionId?',
                        element: <MergedResults />
                    },
                    {
                        path: '/voter-verification/:electionId?',
                        element: <ViewVoterVerification />
                    },
                    {
                        path: '/confirmed-results/:electionId',
                        element: <ConfirmedResults />
                    },
                    {
                        path: '/eligible-individuals/:electionId?',
                        element: <EligibleIndividuals />
                    },
                    {
                        path: '/election-team/:electionId',
                        element: <ElectionTeam />
                    },
                    {
                        path: '/election-stations/:electionId',
                        element: <SetUpStations />
                    },
                    {
                        path: '/reports/:electionId',
                        element: <Reporting />
                    },
                    {
                        path: '/station-report/:stationId/:electionId',
                        element: <ViewStationReport />
                    },
                    {
                        path: '/tie-round-info/:electionId/:roundId',
                        element: <RoundResultsInfo />
                    },
                    {
                        path: '/voting-statistic/:electionId',
                        element: <VotingStatistic />
                    }
                ]
            }
        ]
    },
    {
        element: <RequireAuth allowedRoles={[Role.CHIEF_TELLER_ASSISTANT, Role.CHIEF_TELLER, Role.TELLING_STATION]} />,
        children: [
            {
                element: <Layout />,
                children: [
                    {
                        path: '/ballot/:ballotId',
                        element: <Ballot />
                    }
                ]
            }
        ]
    },
    {
        element: <RequireAuth allowedRoles={[Role.LSA_TELLER]} />,
        children: [
            {
                element: <AdaptiveLayout />,
                children: [
                    {
                        path: '/officers-election-session',
                        element: <OfficersElection />
                    }
                ]
            },
            {
                element: <AdaptiveLayout />,
                children: [
                    {
                        path: '/election-results/:electionId',
                        element: <ViewElectionResults />
                    }
                ]
            }
        ]
    },
    {
        element: <RequireAuth allowedRoles={[Role.SYSTEM_ADMIN]} />,
        children: [
            {
                element: <Layout />,
                children: [
                    {
                        path: '/config',
                        element: <Configuration />
                    }
                ]
            }
        ]
    },
    {
        element: <RequireAuth allowedRoles={[Role.NSA_ADMIN, Role.SYSTEM_ADMIN]} />,
        children: [
            {
                element: <Layout />,
                children: [
                    {
                        path: '/rbc/:electionPeriodId',
                        element: <RbcEditing />
                    },
                    {
                        path: '/rbc-election-board/:electionPeriodId',
                        element: <RbcElectionBoard />
                    }
                ]
            }
        ]
    },
    {
        path: '*',
        element:
            <RoleBasedLayout>
                <PageNotFound />
            </RoleBasedLayout>,
        children: [
            {
                path: '*',
                element: <PageNotFound />
            }
        ]
    }
])