import { useAuth } from 'hooks'
import { Role } from 'models'
import { lazy } from 'react'
import { useNavigate } from 'react-router-dom'

const AdminDashboard = lazy(() => import('features/administration/pages/Dashboard'))
const LsaOfficerDashboard = lazy(() => import('features/electionManagement/pages/Dashboard'))
const OnlineVoterDashboard = lazy(() => import('features/onlineVoting/pages/Dashboard'))
const TellingStationDashboard = lazy(() => import('features/tellingStation/pages/Dashboard'))
const OfficersElectionTellerDashboard = lazy(() =>
    import('features/officersElection/features/dashboard/pages/Dashboard'))
const ChiefTellerDashboard = lazy(() =>
    import('features/electionProcessing/features/dashboard/pages/Dashboard'))

function DefaultRouter() {
    const { auth } = useAuth()
    const navigate = useNavigate()
    if (!auth?.role) {
        navigate('/sign-in', { replace: true })
    }
    switch (auth!.role) {
        case Role.SYSTEM_ADMIN:
        case Role.NSA_ADMIN:
            return <AdminDashboard />
        case Role.LSA_OFFICER:
            return <LsaOfficerDashboard />
        case Role.VOTER:
            return <OnlineVoterDashboard />
        case Role.TELLING_STATION:
            return <TellingStationDashboard />
        case Role.LSA_TELLER:
            return <OfficersElectionTellerDashboard />
        default:
            return <ChiefTellerDashboard />
    }
}

export default DefaultRouter