import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { ElectionParticipant } from 'models'

type VotersResponse = {
    voters: ElectionParticipant[],
    totalCount: number
}

export const useElectionVoters = (electionId: string, enabled?: boolean, frozen?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getVoters = async ({ queryKey }: QueryFunctionContext)
        : Promise<VotersResponse> => {
        const [, electionId] = queryKey

        const result = await axios<VotersResponse>(
            `${moduleUrl}elections/${electionId}${frozen ? '/frozen' : ''}/voters`)

        return result.data
    }

    return useQuery({
        queryKey: ['voters' + (frozen ? '-frozen' : ''), electionId],
        staleTime: 1000 * 60 * 20,
        queryFn: getVoters,
        enabled
    })
}