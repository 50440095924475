import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl } from 'api'
import axios from 'axios'
import { getCurrentDateForLogging } from 'utils'

export type AuthSaParams = {
    login: string,
    password: string
}

export function useAuthSa() {
    const moduleUrl = getModuleUrl(ApiModule.AuthenticationService)

    async function authSystemAdmin({ login, password }: AuthSaParams) {
        const api = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
                'User-Local-Time-and-Time-Zone': getCurrentDateForLogging(),
                'Authorization': 'Basic ' + btoa(`${login}:${password}`)
            },
            withCredentials: true
        })

        const response = await api(moduleUrl
            + 'admin-user/authenticate',
            {
                method: 'post'
            })

        return {
            accessToken: response.headers['access-token']
        }
    }

    return useMutation({
        mutationFn: authSystemAdmin
    })
}