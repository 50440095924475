import { useCheckReportStatus, useGenerateReport, useGetReport } from 'api'
import { queryClient } from 'config/query-client'
import { ReportFormat, ReportStatus, ReportType } from 'models'
import { useEffect, useState } from 'react'

interface JsonReportLoaderParams<T> {
    reportType: ReportType
    params: Record<string, any>
    onReportReady?: (report: T) => void
    cached?: boolean
    enabled?: boolean
}

export function useJsonReportLoader<T>({
    reportType,
    params,
    onReportReady,
    cached,
    enabled
}: JsonReportLoaderParams<T>) {
    const [reportIsReady, setReportIsReady] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [reportError, setReportError] = useState<string>('')
    const [timerId, setTimerId] = useState<NodeJS.Timer | null>(null)

    const { data: reportId } = useGenerateReport({
        reportType,
        reportFormat: ReportFormat.JSON,
        params
    }, enabled, cached)

    const { data: status } = useCheckReportStatus(reportId || '', !!reportId)
    const { data: report } = useGetReport<T>(reportId || '', reportIsReady)

    useEffect(() => {
        if (status) {
            switch (status) {
                case ReportStatus.PENDING:
                case ReportStatus.IN_PROGRESS:
                    if (!timerId) {
                        setIsLoading(true)
                        const newTimerId = setInterval(() => {
                            queryClient.invalidateQueries(['report-status', reportId])
                        }, 1000)
                        setTimerId(newTimerId)
                    }
                    break
                case ReportStatus.COMPLETED:
                    if (timerId) {
                        clearInterval(timerId)
                    }
                    setTimerId(null)
                    setIsLoading(false)
                    setReportIsReady(true)
                    break
                case ReportStatus.FAILED:
                    if (timerId) {
                        clearInterval(timerId)
                    }
                    setTimerId(null)
                    setIsLoading(false)
                    setReportError(`Report generation failed: ${status}`)
                    break
                default:
                    if (timerId) {
                        clearInterval(timerId)
                    }
                    setTimerId(null)
                    setIsLoading(false)
                    break
            }
        }

        return () => {
            if (timerId) {
                clearInterval(timerId)
            }
        }
    }, [status, reportId, timerId])

    useEffect(() => {
        if (reportIsReady && report && onReportReady) {
            onReportReady(report)
        }
    }, [report, reportIsReady])

    return {
        isLoading,
        reportError,
        report
    }
}
