import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

export function useRestartTrainingSession() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Starter)

    async function restartSession() {
        return await axios(`${moduleUrl}restart`,
            {
                method: 'post',
                data: {}
            })
    }

    return useMutation({
        mutationFn: restartSession
    })
}