import { ConfigDateType, ElectionType, ElectionWordingConfiguration, Restriction } from 'models'
import { useTranslation } from 'react-i18next'
import { dayjsToString, isFormValid, richTextEditorEmptyValue } from 'utils'
import { useChangeRestriction, useUpdateElectionWordingConfiguration } from '../../api/mutations'
import { useElectionConfigData } from '../../hooks/useElectionConfigData'
import { ChangeRestrictionData, ElectionConfigData, ElectionPeriod } from '../../model'
import { getElectionRestriction } from '../../utils'
import style from './index.module.scss'

function ElectionConfigTitle() {
    const { t } = useTranslation('configuration')
    const { electionConfigData, setElectionConfigData } = useElectionConfigData()
    const { mutateAsync: changeRestriction } = useChangeRestriction()
    const { mutateAsync: updateWordingConfig } = useUpdateElectionWordingConfiguration()

    const prepareChangeRestrictionData
        = (values: any, electionType: ElectionType, electionPeriod: ElectionPeriod): ChangeRestrictionData => {
            const formatDate = (date: any) => date ? dayjsToString(date, 'YYYY-MM-DD') : undefined
            const formatTime = (time: any) => time ? dayjsToString(time, 'HH:mm:ss') : undefined

            const getDateAndTime = (dateTime: any) => {
                if (!dateTime) return { date: undefined, time: undefined }
                const date = formatDate(dateTime)
                const time = formatTime(dateTime)

                return { date, time }
            }

            const { date: onlineVotingEndDate, time: onlineVotingEndTime }
                = getDateAndTime(values.onlineVotingEndTypeDateTime)
            const { date: voteCountingStartDate, time: voteCountingStartTime }
                = getDateAndTime(values.startTellingProcessDateTypeDateTime)
            const { date: inPersonVotingAvailableFromDate, time: inPersonVotingAvailableFromTime }
                = getDateAndTime(values.showVotingInPersonPopupDateTypeDateTime)

            const restrictionData: Restriction = {
                electionCreationEnabled: values.electionEnabled ?? false,
                bypassFlowAllowed: values.byPassFlowEnabled,
                onlineVotingStartRangeMin: formatDate(values.startDateRangeFrom),
                onlineVotingStartRangeMax: formatDate(values.startDateRangeTo),
                onlineVotingEndType: values.onlineVotingEndType,
                onlineVotingEnd: values.onlineVotingEndType === ConfigDateType.CERTAIN_DATE
                    ? onlineVotingEndDate
                    : formatDate(electionConfigData.electionRestrictions?.onlineVotingEnd),
                onlineVotingEndTime: values.onlineVotingEndType === ConfigDateType.CERTAIN_DATE
                    ? onlineVotingEndTime
                    : formatTime(values.onlineVotingEndTypeTime),
                electionDateRangeFrom: formatDate(values.electionDateRangeFrom),
                electionDateRangeTo: formatDate(values.electionDateRangeTo),
                voteCountingStartType: values.startTellingProcessDateType,
                voteCountingStart: values.startTellingProcessDateType === ConfigDateType.CERTAIN_DATE
                    ? voteCountingStartDate
                    : formatDate(electionConfigData.electionRestrictions?.voteCountingStart),
                voteCountingStartTime: values.startTellingProcessDateType === ConfigDateType.CERTAIN_DATE
                    ? voteCountingStartTime
                    : formatTime(values.startTellingProcessDateTypeTime),
                inPersonVotingAvailable: values.onlineVotingInPersonPromptEnabled,
                inPersonVotingAvailableFromType: values.showVotingInPersonPopupDateType,
                inPersonVotingAvailableFrom: values.showVotingInPersonPopupDateType === ConfigDateType.CERTAIN_DATE
                    ? inPersonVotingAvailableFromDate
                    : formatDate(electionConfigData.electionRestrictions?.inPersonVotingAvailableFrom),
                inPersonVotingAvailableFromTime: values.showVotingInPersonPopupDateType === ConfigDateType.CERTAIN_DATE
                    ? inPersonVotingAvailableFromTime
                    : formatTime(values.showVotingInPersonPopupDateTypeTime)
            }

            return {
                electionType,
                electionPeriod,
                data: restrictionData
            }
        }

    const save = async () => {
        if (!electionConfigData.form || !electionConfigData.electionType || !electionConfigData.electionPeriod)
            return

        const { form, electionType, electionPeriod } = electionConfigData

        if (await isFormValid({ form })) {
            const values = form.getFieldsValue(true)

            const changeRestrictionData = prepareChangeRestrictionData(values, electionType, electionPeriod)
            const electionRestriction = getElectionRestriction(changeRestrictionData)

            await changeRestriction({
                electionType,
                electionPeriod,
                data: electionRestriction
            })

            const wordingConfig: ElectionWordingConfiguration = {
                electionType,
                electionStage: 1,
                importantMessage: '',
                votingInPersonTitle: values.votingInPersonTitle || '',
                votingInPersonBody: values.votingInPersonBody || '',
                votingInPersonYes: values.votingInPersonYes || '',
                votingInPersonNo: values.votingInPersonNo || ''
            }

            if (values.importantMessage && values.importantMessage !== richTextEditorEmptyValue) {
                wordingConfig.importantMessage = values.importantMessage
            }

            await updateWordingConfig(wordingConfig)

            setElectionConfigData((prev: ElectionConfigData) => ({ ...prev, isFormTouched: false }))
        }
    }

    return (
        <div className={style.content}>
            <div className={style.label}>{t('election_configuration')}</div>
            <div>
                <button className="btn-main-primary"
                    disabled={!electionConfigData.isFormTouched}
                    onClick={() => save()}
                >
                    <span>{t('save_changes')}</span>
                </button>
            </div>
        </div>
    )
}

export default ElectionConfigTitle