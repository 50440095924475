import { ElectionType } from 'models'
import { useTranslation } from 'react-i18next'

export const useElectionTypeMap = () => {
    const { t } = useTranslation('election')

    return {
        toString: (type: ElectionType): string => {
            switch (type) {
                case ElectionType.LOCAL_DELEGATE_ELECTION:
                    return t('local_delegate_election')
                case ElectionType.RIDVAN_ELECTION:
                    return t('ridvan_election')
                case ElectionType.OFFICERS_ELECTION:
                    return t('officers_election')
                case ElectionType.BREAKING_TIE:
                    return t('teller:break_the_tie')
                case ElectionType.BY_ELECTION:
                    return t('by_election')
                case ElectionType.NATIONAL_DELEGATE_ELECTION:
                    return t('national_delegate_election')
                case ElectionType.RBC_ELECTION:
                    return t('balloting_for_regional_bahai_councils')
            }

            return 'Unknown election type'
        }
    }

}
