import { AbilityBuilder, MongoAbility, createMongoAbility } from '@casl/ability'
import { Auth } from 'models'
import { Actions } from './ability.actions'
import { Subjects } from './ability.subjects'
import { defineBahaiIdCopyingToDashboardAbility } from './bahai-id-copying-to-clipboard.a'
import { defineConfigAbility } from './config.actions'
import { defineElectionBoardAbility } from './election-board.a'
import { defineFunctionAbility } from './function.a'
import { defineHeaderElementsAbility } from './header-elements.a'
import { defineManageStationBallotAbility } from './manage-station-ballot.a'
import { defineManageTellersAbility } from './manage-tellers.a'
import { defineMergedResultsAbility } from './merged-results'
import { reportsAbility } from './reports'

export type AppAbility = MongoAbility<[Actions, Subjects]>
export function defineAbility(auth?: Auth) {
    const builder = new AbilityBuilder<AppAbility>(createMongoAbility)

    defineHeaderElementsAbility(builder, auth)
    defineElectionBoardAbility(builder, auth)
    defineManageTellersAbility(builder, auth)
    defineManageStationBallotAbility(builder, auth)
    defineFunctionAbility(builder, auth)
    defineMergedResultsAbility(builder, auth)
    defineBahaiIdCopyingToDashboardAbility(builder, auth)
    defineConfigAbility(builder, auth)
    reportsAbility(builder, auth)

    const { build } = builder

    return build()
}