import { PropsWithChildren } from 'react'
import Spinner from '../Spinner'
import style from './index.module.scss'

const SpinnerWrapper = ({ children }: PropsWithChildren) => <>
    {
        <div className={style.spinWrapper}>
            <Spinner />
            {children}
        </div>
    }
</>

export default SpinnerWrapper