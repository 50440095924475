import { useQueries } from '@tanstack/react-query'
import { useAxios } from 'api/hooks'
import { getVoteCountingStarted } from './voteCountingStarted'

export const useVoteCountingStartedBatch = (ids: string[], enabled?: boolean) => {
    const axios = useAxios()


    return useQueries({
        queries: ids.map(id => ({
            queryKey: ['vote-counting-started', id],
            queryFn: () => getVoteCountingStarted(axios, id),
            enabled
        }))
    })
}