export enum ReportType {
    LsaElections = 'lsa-elections',
    OfficersElections = 'officers-elections',
    NationalDelegateElections = 'national-delegate-elections',
    RidvanConsolidatedReport = 'ridvan-consolidated-report',
    LocalDelegateElections = 'local-delegate-elections',
    ElectedCandidateTellerReport = 'elected-candidate-teller-report',
    ConfirmedCandidateTellerReport = 'confirmed-candidate-teller-report',
    LocalDelegateElectionSummaryReport = 'local-delegate-election-summary-report',
    ElectionStatistics = 'lsa-election-statistics',
    EligibleIndividuals = 'eligible-individuals',
    VoterVerificationList = 'voter-verification-list',
    OfficersElectionResultsReport = 'officers-election-results-report',
    RejectedUnityWebPushReasonReport = 'rejected-unity-web-push-report',
    OnlineVotingStatistics = 'online-voting-statistics',
    RegionalBahaiCouncilInstitutions = 'regional-bahai-council-institutions',
    InvalidBallotAndVoteoteReasonsReport = 'invalid-ballot-and-vote-reasons',
    TellingStationCommentsReport = 'telling-station-comments',
    BallotingStatisticsReport = 'national-delegate-election-balloting-statistics',
    NationalDelegateElectionResultsReport = 'national-delegate-election-results',
    VoterPartisipationDetailsReport = 'voter-participation-details',
    VoterPartisipationByAgeCategories = 'voter-by-age-and-voting-type-consolidated',
    DetailedVoterParticipation = 'detailed-voter-participation',
    VoterByAgeAndVotingTypeReport = 'voter-by-age-and-voting-type',
    RbcElections = 'rbc-elections',
    RbcAnnualVotersParticipationConsolidatedReport = 'rbc-annual-voter-participation-consolidated',
    RbcElectionStatistics = 'rbc-election-statistics',
    DetailedLsaParticipation = 'detailed-lsa-participation',
    RbcBallotingStatistics = 'rbc-balloting-statistics',
    RolesAndDemographicData = 'roles-and-demographic-data',
    RbcElectionConfirmedResultsReport = 'rbc-election-confirmed-results-report',
    RbcContactInformationByRegions = 'rbc-contact-information-by-regions',
    AnnualLsaMemberParticipation = 'annual-lsa-member-participation'
}