import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { ElectionParticipant } from 'models'
import { getUrlParamsFromFilter, transformSortable } from 'utils'
import { ParticipantFilter } from '../../../../models'

export const useParticipants = (electionPeriodId: string,
    filter?: ParticipantFilter,
    enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getParticipants = async ({ queryKey }: QueryFunctionContext)
        : Promise<ElectionParticipant[]> => {
        const [, electionPeriodId, filter] = queryKey

        const queryParams = getUrlParamsFromFilter(transformSortable(filter as ParticipantFilter))
        const result = await axios<ElectionParticipant[]>(
            `${moduleUrl}by-election-periods/${electionPeriodId}/by-election/participants` +
            `${queryParams ? `?${queryParams}` : ''}`)

        return result.data
    }

    return useQuery({
        queryKey: ['election-period-participant', electionPeriodId, filter],
        queryFn: getParticipants,
        enabled,
        cacheTime: 0
    })
}