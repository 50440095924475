import { Form } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { useElectionRestrictionsOld, useElectionWordingConfiguration } from 'api'
import Preloader from 'components/molecules/Preloader'
import dayjs from 'dayjs'
import { ConfigDateType, ElectionType } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ConfigWrapper from '../../../../components/ConfigWrapper'
import Divider from '../../../../components/Divider'
import { useElectionConfigData } from '../../hooks/useElectionConfigData'
import { ElectionPeriod } from '../../model'
import GeneralSettings from '../GeneralSettings'
import ImportantMessage from '../ImportantMessage'
import InPersonPopupWordingSettings from '../InPersonPopupWordingSettings'
import ShowVotingInPersonPopup from '../ShowVotingInPersonPopup'
import StartTellingProcessFrom from '../StartTellingProcessFrom'


function RbcElectionConfig() {
    const { t } = useTranslation('configuration')
    const [form] = useForm()

    const [isFormInitialized, setIsFormInitialized] = useState(false)

    const { setElectionConfigData } = useElectionConfigData()

    const { data: restrictions, isLoading: isLoadingRestrictions } = useElectionRestrictionsOld()
    const { data: wordingConfiguration, isLoading: isLoadingWording } = useElectionWordingConfiguration(
        ElectionType.RBC_ELECTION
    )

    useEffect(() => {
        form.setFieldsValue({
            onlineVotingInPersonPromptEnabled: false,
            electionEnabled: false,
            byPassFlowEnabled: false
        })

        setElectionConfigData(current => {
            const updatedData = {
                ...current,
                form,
                isFormTouched: false,
                electionType: ElectionType.RBC_ELECTION,
                electionPeriod: ElectionPeriod.RBC_ELECTION_PERIODS
            }

            return updatedData
        })
    }, [form, setElectionConfigData])

    useEffect(() => {
        if (restrictions && wordingConfiguration) {
            const rbcRestrictions = restrictions.rbcElectionRestriction
            setElectionConfigData(current => {
                const updatedData = {
                    ...current,
                    electionRestrictions: rbcRestrictions
                }

                return updatedData
            })

            const parseTime = (timeString: string | null | undefined) => {
                if (!timeString) return null
                const parsed = dayjs(timeString, 'HH:mm:ss')

                return parsed.isValid() ? parsed : null
            }

            const formValues = {
                electionEnabled: rbcRestrictions.electionCreationEnabled,
                byPassFlowEnabled: rbcRestrictions.bypassFlowAllowed,
                startTellingProcessDateTypeTime: parseTime(rbcRestrictions.voteCountingStartTime),
                startTellingProcessDateTypeDateTime: rbcRestrictions.voteCountingStart
                    ? dayjs(`${rbcRestrictions.voteCountingStart} ${rbcRestrictions.voteCountingStartTime
                        || 'HH:mm:ss'}`) : null,
                startTellingProcessDateType: rbcRestrictions.voteCountingStartType || ConfigDateType.ELECTION_DAY,
                showVotingInPersonPopupDateTypeDateTime: rbcRestrictions.inPersonVotingAvailableFrom
                    ? dayjs(`${rbcRestrictions.inPersonVotingAvailableFrom} 
                        ${rbcRestrictions.inPersonVotingAvailableFromTime || 'HH:mm:ss'}`) : null,
                showVotingInPersonPopupDateTypeTime: parseTime(rbcRestrictions.inPersonVotingAvailableFromTime),
                showVotingInPersonPopupDateType: rbcRestrictions.inPersonVotingAvailableFromType
                    || ConfigDateType.ELECTION_DAY,
                onlineVotingInPersonPromptEnabled: rbcRestrictions.inPersonVotingAvailable,

                importantMessage: wordingConfiguration.importantMessage,
                votingInPersonTitle: wordingConfiguration.votingInPersonTitle,
                votingInPersonBody: wordingConfiguration.votingInPersonBody,
                votingInPersonYes: wordingConfiguration.votingInPersonYes,
                votingInPersonNo: wordingConfiguration.votingInPersonNo
            }

            form.setFieldsValue(formValues)
            setIsFormInitialized(true)
        }
    }, [restrictions, wordingConfiguration, setElectionConfigData, form])

    if (isLoadingRestrictions || isLoadingWording || !isFormInitialized) {
        return <Preloader />
    }

    return (
        <ConfigWrapper >
            <Form
                layout="vertical"
                form={form}
                onValuesChange={() => {
                    setElectionConfigData(current => ({ ...current, isFormTouched: true }))
                }}>
                <GeneralSettings form={form}
                    flowCheckboxOnly
                    enabledTitle={t('enable_disable_national_delegate_election')}
                    enabledSubtitle={
                        <>
                            <div>{t('applicable_only_for_hosting_lsa_only')}.</div>
                            <div>{t('even_if_the_checkbox_will_allow_particular_type_of_election')}</div>
                        </>
                    }
                />
                <Divider />
                <StartTellingProcessFrom
                    form={form}
                />
                <Divider />
                <ShowVotingInPersonPopup
                    form={form}
                />
                <InPersonPopupWordingSettings
                    form={form} bodyInitValue={wordingConfiguration?.votingInPersonBody}
                />
                <Divider />

                <ImportantMessage
                    isRequired={false}
                    form={form}
                    importantInitValue={wordingConfiguration?.importantMessage}
                />
            </Form>
        </ConfigWrapper>
    )
}

export default RbcElectionConfig
