import {
    ADMINISTRATION_URL,
    AUTHENTICATION_URL,
    BASE_URL,
    NOTIFICATION_URL,
    ONLINE_BALLOT_URL,
    REPORT_URL,
    TELLING_STATION_URL,
    TOOLKIT_URL,
    STARTER_URL
} from 'api/obs-api'
import { ApiModule } from '../api-module'

export const getModuleUrl = (apiModule: ApiModule) => {
    switch (apiModule) {
        case ApiModule.TellingStation:
            return TELLING_STATION_URL + ApiModule.TellingStation
        case ApiModule.Administration:
            return ADMINISTRATION_URL + ApiModule.Administration
        case ApiModule.AuthenticationService:
            return AUTHENTICATION_URL + ApiModule.AuthenticationService
        case ApiModule.OnlineBallot:
            return ONLINE_BALLOT_URL + ApiModule.OnlineBallot
        case ApiModule.Report:
            return REPORT_URL + ApiModule.Report
        case ApiModule.Notification:
            return NOTIFICATION_URL + ApiModule.Notification
        case ApiModule.Toolkit:
            return TOOLKIT_URL + ApiModule.Toolkit
        case ApiModule.Starter:
            return STARTER_URL
        default:
            return BASE_URL + apiModule
    }

}