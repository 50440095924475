import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { Election, ElectionType } from 'models'

type ElectionsParams = {
    type: ElectionType,
    bahaiYear: number,
    includeDraft?: boolean
}

export const useElections = ({
    bahaiYear,
    type,
    includeDraft
}: ElectionsParams, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getBahaiYears = async () => {
        const elections = await axios<Election[]>(moduleUrl + 'elections?' +
            `balloting-year=${bahaiYear}` +
            `&type=${type}` +
            (includeDraft ? '&draft=true' : ''))

        return elections.data
    }

    return useQuery({
        queryKey: ['elections', type, bahaiYear, includeDraft],
        queryFn: getBahaiYears,
        enabled
    })
}