import Footer from 'components/molecules/Footer'
import { useMyDetails } from './api'
import ActionsPanel from './components/ActionsPanel'
import SelectFunctionPanel from './components/SelectFunctionPanel'
import SignInPanel from './components/SignInPanel'
import AuthPageWrapper from './components/common/AuthPageWrapper'
import Header from './components/common/Header'
import { useAvailableRoles } from './hooks'
import { resetRoleRelatedQueriesCache } from './utils'

export const auth = {
    api: {
        query: { useMyDetails }
    },
    components: {
        ActionsPanel,
        Header,
        AuthPageWrapper,
        SignInPanel,
        Footer,
        SelectFunctionPanel
    },
    hooks: {
        useAvailableRoles
    },
    utils: {
        resetRoleRelatedQueriesCache
    }
} 
