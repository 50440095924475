export enum ElectionType {
    RIDVAN_ELECTION = 'RIDVAN_ELECTION',
    LOCAL_DELEGATE_ELECTION = 'LOCAL_DELEGATE_ELECTION',
    BY_ELECTION = 'BY_ELECTION',
    NATIONAL_DELEGATE_ELECTION = 'NATIONAL_DELEGATE_ELECTION',
    OFFICERS_ELECTION = 'OFFICERS_ELECTION',
    BREAKING_TIE = 'BREAKING_TIE',
    RBC_ELECTION = 'RBC_ELECTION'
}

