import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

i18n
    .use(initReactI18next)
    .use(HttpApi)
    .use(LanguageDetector)
    .init({
        ns: [
            'administration',
            'auth',
            'ballot',
            'common',
            'election',
            'results',
            'teller',
            'telling-station',
            'telling-station-sandbox',
            'station',
            'officers-election',
            'voter'
        ],
        fallbackNS: 'common',
        fallbackLng: 'en-US',
        debug: process.env.NODE_ENV !== 'production',
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json'
        },
        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            caches: ['cookie', 'localStorage'],
            lookupQuerystring: 'lng'
        }
    })

export default i18n