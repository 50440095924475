import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { RbcElectionsColoring } from 'models'

export const useRbcElectionsColoring = (enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Report)

    const getRbcElectionsColoring = async (): Promise<RbcElectionsColoring[]> => {
        const restrictions = await axios<RbcElectionsColoring[]>(moduleUrl + 'rbc/coloring')

        return restrictions.data
    }

    return useQuery({
        queryKey: ['rbc-coloring'],
        queryFn: getRbcElectionsColoring,
        enabled
    })
}