import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

export const useGenerateIntructions = (enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Starter)

    const generateInstructions = async () => {
        const res =
            await axios(`${moduleUrl}instruction/generate`)

        return res.status
    }

    return useQuery({
        queryKey: ['instructions'],
        queryFn: generateInstructions,
        enabled
    })
}

export const useInstructionUrl = () => {
    const moduleUrl = getModuleUrl(ApiModule.Starter)

    const getInstructionUrl = (electionId: string) => moduleUrl + `instruction/download/${electionId}`

    return {
        getInstructionUrl
    }
}