import { usePreloaderVisible } from 'hooks'
import { ReactNode, useEffect } from 'react'
import SpinnerWrapper from '../SpinnerWrapper'
import style from './index.module.scss'

type PreloaderProps = {
    title?: ReactNode
}

const Preloader = ({ title }: PreloaderProps) => {
    const { setVisiblePreloadersCount } = usePreloaderVisible()

    useEffect(() => {
        if (!title) {
            setVisiblePreloadersCount(current => current + 1)

            return () => setVisiblePreloadersCount(current => (current - 1))
        }
    }, [])


    return <>
        {!!title && <SpinnerWrapper>
            <div className={style.title}>{title}</div>
        </SpinnerWrapper>}
    </>
}

export default Preloader