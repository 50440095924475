import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

export function usePlanRbcElectionPeriod() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    async function planRbcElectionPeriod() {
        const res = await axios<{ electionPeriod: number }>(moduleUrl + 'rbc-election-periods/plan',
            {
                method: 'post'
            })

        return res.data.electionPeriod
    }

    return useMutation({
        mutationFn: planRbcElectionPeriod
    })
}