import { Form } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { useElectionRestrictionsOld, useElectionWordingConfiguration } from 'api'
import Preloader from 'components/molecules/Preloader'
import dayjs from 'dayjs'
import { ConfigDateType, ElectionType } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toDayjs } from 'utils'
import ConfigWrapper from '../../../../components/ConfigWrapper'
import Divider from '../../../../components/Divider'
import { useElectionConfigData } from '../../hooks/useElectionConfigData'
import { ElectionPeriod } from '../../model'
import ElectionDate from '../ElectionDate'
import GeneralSettings from '../GeneralSettings'
import ImportantMessage from '../ImportantMessage'
import InPersonPopupWordingSettings from '../InPersonPopupWordingSettings'
import OnlineVotingDateAndTime from '../OnlineVotingDateAndTime'
import OnlineVotingStartDate from '../OnlineVotingStartDate'
import ShowVotingInPersonPopup from '../ShowVotingInPersonPopup'
import StartTellingProcessFrom from '../StartTellingProcessFrom'

function NationalDelegateElectionConfig() {
    const { t } = useTranslation('configuration')
    const [form] = useForm()
    const [isFormInitialized, setIsFormInitialized] = useState(false)
    const { setElectionConfigData } = useElectionConfigData()
    const { data: restrictions, isLoading: isLoadingRestrictions } = useElectionRestrictionsOld()
    const { data: wordingConfiguration, isLoading: isLoadingWording } = useElectionWordingConfiguration(
        ElectionType.NATIONAL_DELEGATE_ELECTION
    )

    useEffect(() => {
        form.setFieldsValue({
            onlineVotingInPersonPromptEnabled: false,
            electionEnabled: false,
            byPassFlowEnabled: false
        })

        setElectionConfigData(current => {
            const updatedData = {
                ...current,
                form,
                isFormTouched: false,
                electionType: ElectionType.NATIONAL_DELEGATE_ELECTION,
                electionPeriod: ElectionPeriod.NATIONAL_DELEGATE_ELECTION_PERIODS
            }

            return updatedData
        })
    }, [form, setElectionConfigData])

    useEffect(() => {
        if (restrictions && wordingConfiguration) {
            const ndeRestrictions = restrictions.nationalDelegateElectionRestriction

            setElectionConfigData(current => ({
                ...current,
                electionRestrictions: ndeRestrictions
            }))

            const parseTime = (timeString: string | null | undefined) => {
                if (!timeString) return null
                const parsed = dayjs(timeString, 'HH:mm:ss')

                return parsed.isValid() ? parsed : null
            }

            const formValues = {
                electionEnabled: ndeRestrictions.electionCreationEnabled,
                byPassFlowEnabled: ndeRestrictions.bypassFlowAllowed,
                startDateRangeFrom: ndeRestrictions.onlineVotingStartRangeMin
                    ? toDayjs(ndeRestrictions.onlineVotingStartRangeMin) : null,
                startDateRangeTo: ndeRestrictions.onlineVotingStartRangeMax
                    ? toDayjs(ndeRestrictions.onlineVotingStartRangeMax) : null,
                onlineVotingEndType: ndeRestrictions.onlineVotingEndType || ConfigDateType.ELECTION_DAY,
                onlineVotingEndTypeDateTime: ndeRestrictions.onlineVotingEnd
                    ? dayjs(`${ndeRestrictions.onlineVotingEnd} ${ndeRestrictions.onlineVotingEndTime
                        || 'HH:mm:ss'}`) : null,
                onlineVotingEndTypeTime: parseTime(ndeRestrictions.onlineVotingEndTime),
                electionDateRangeFrom: ndeRestrictions.electionDateRangeFrom
                    ? toDayjs(ndeRestrictions.electionDateRangeFrom) : null,
                electionDateRangeTo: ndeRestrictions.electionDateRangeTo
                    ? toDayjs(ndeRestrictions.electionDateRangeTo) : null,
                startTellingProcessDateType: ndeRestrictions.voteCountingStartType || ConfigDateType.ELECTION_DAY,
                startTellingProcessDateTypeDateTime: ndeRestrictions.voteCountingStart
                    ? dayjs(`${ndeRestrictions.voteCountingStart} ${ndeRestrictions.voteCountingStartTime
                        || 'HH:mm:ss'}`) : null,
                startTellingProcessDateTypeTime: parseTime(ndeRestrictions.voteCountingStartTime),
                showVotingInPersonPopupDateType: ndeRestrictions.inPersonVotingAvailableFromType
                    || ConfigDateType.ELECTION_DAY,
                showVotingInPersonPopupDateTypeDateTime: ndeRestrictions.inPersonVotingAvailableFrom
                    ? dayjs(`${ndeRestrictions.inPersonVotingAvailableFrom} 
                        ${ndeRestrictions.inPersonVotingAvailableFromTime || 'HH:mm:ss'}`) : null,
                showVotingInPersonPopupDateTypeTime: parseTime(ndeRestrictions.inPersonVotingAvailableFromTime),
                onlineVotingInPersonPromptEnabled: ndeRestrictions.inPersonVotingAvailable,

                importantMessage: wordingConfiguration.importantMessage,
                votingInPersonTitle: wordingConfiguration.votingInPersonTitle,
                votingInPersonBody: wordingConfiguration.votingInPersonBody,
                votingInPersonYes: wordingConfiguration.votingInPersonYes,
                votingInPersonNo: wordingConfiguration.votingInPersonNo
            }

            form.setFieldsValue(formValues)
            setIsFormInitialized(true)
        }
    }, [restrictions, wordingConfiguration, setElectionConfigData, form])

    if (isLoadingRestrictions || isLoadingWording || !isFormInitialized) {
        return <Preloader />
    }

    return (
        <ConfigWrapper >
            <Form
                layout="vertical"
                form={form}
                onValuesChange={() => {
                    setElectionConfigData(current => ({ ...current, isFormTouched: true }))
                }}>
                <GeneralSettings form={form}
                    enabledTitle={t('enable_disable_national_delegate_election')}
                    enabledSubtitle={
                        <>
                            <div>{t('applicable_only_for_hosting_lsa')}.</div>
                            <div>{t('even_if_the_checkbox_will_allows_a_particular_type_of_election')}</div>
                        </>
                    }
                />
                <Divider />
                <OnlineVotingStartDate
                    form={form}
                />
                <Divider />
                <OnlineVotingDateAndTime
                    form={form}
                />
                <Divider />
                <ElectionDate
                    form={form}
                />
                <Divider />
                <StartTellingProcessFrom
                    form={form}
                />
                <Divider />
                <ShowVotingInPersonPopup
                    form={form}
                />
                <InPersonPopupWordingSettings
                    form={form} bodyInitValue={wordingConfiguration?.votingInPersonBody}
                />
                <Divider />

                <ImportantMessage
                    form={form}
                    importantInitValue={wordingConfiguration?.importantMessage}
                />
            </Form>
        </ConfigWrapper>
    )
}

export default NationalDelegateElectionConfig
