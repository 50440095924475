type BatchFileDownloaderProps = {
    urls: string[]
}
function BatchFileDownloader({ urls }: BatchFileDownloaderProps) {
    return (
        <div style={{ display: 'none' }}>
            {
                urls.map((url, index) => <iframe src={url} title={index.toString()} key={index} />)
            }
        </div>
    )
}

export default BatchFileDownloader