import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

export function useFinish() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Starter)

    async function castBallot() {
        return await axios(`${moduleUrl}finish`,
            {
                method: 'post',
                withCredentials: true,
                data: {}
            })
    }

    return useMutation({
        mutationFn: castBallot
    })
}