import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { OnlineVotingInfo } from 'models'

export const useOnlineVoting = (id: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getOnlineVoting = async () => {
        const res = await axios<OnlineVotingInfo>(`${moduleUrl}elections/${id}/online-voting`)

        return res.data
    }

    return useQuery<OnlineVotingInfo>({
        queryKey: ['online-voting', id],
        queryFn: getOnlineVoting,
        enabled
    })
}