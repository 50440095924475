import { PopupProps } from 'components/molecules/Popup'
import { useTranslation } from 'react-i18next'
import DeleteElection from '../components/DeleteElection'

export const useDeleteElection = () => {
    const { t } = useTranslation('election')

    const deleteElection = (show: (props: PopupProps) => void,
        hide: () => void) => {
        show({
            width: '660px',
            title: <div style={{ width: '100%', textAlign: 'center' }}>{t('delete_election')}</div>,
            children: <DeleteElection onCancel={hide} onSubmit={() => { hide() }} />,
            footer: null
        })
    }

    return { deleteElection }
}