import { AbilityBuilder } from '@casl/ability'
import { Auth, Role } from 'models'
import { isSandbox } from 'utils'
import { AppAbility } from './ability'

export type HeaderElementsSubject =
    'HomeIcon' | 'LogoBig' | 'Logo' | 'NavigationElement' | 'Locality'
    | 'RoleNav' | 'ViewButton' | 'UnmergeResultsButtons' | 'EndTraining' | 'DeleteElectionButton'

export function defineHeaderElementsAbility(builder: AbilityBuilder<AppAbility>, auth?: Auth) {
    const { can } = builder

    if (!isSandbox && auth?.role !== Role.VOTER) {
        can('view', 'HomeIcon')
        can('view', 'Logo')
        can('view', 'NavigationElement')
    }

    if (auth?.role !== Role.VOTER && !isSandbox) {
        can('view', 'Locality')
    }

    if (auth?.role === Role.SYSTEM_ADMIN) {
        can('view', 'ViewButton')
        can('view', 'DeleteElectionButton')
    }

    if (auth?.role === Role.VOTER
        || (auth?.role === Role.TELLING_STATION && isSandbox)) {
        can('view', 'LogoBig')
    }

    if (auth && auth.role !== Role.TELLING_STATION) {
        can('view', 'RoleNav')
    }

    if (auth?.role === Role.TELLING_STATION && isSandbox) {
        can('perform', 'EndTraining')
    }

    if (auth && (auth.role === Role.CHIEF_TELLER ||
        auth?.role === Role.CHIEF_TELLER_ASSISTANT)) {
        can('view', 'UnmergeResultsButtons')
    }
}