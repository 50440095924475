import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { Ballot } from 'models'
import { getUrlParamsFromFilter } from 'utils'

type BallotsFilter = {
    offset?: number,
    limit?: number
}

export const useOnlineBallots = (electionId: string, 
        filter?: BallotsFilter, 
        enabled?: boolean, 
        noGlobalPreloader?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.OnlineBallot)

    const getOnlineBallots = async ({ queryKey }: QueryFunctionContext)
        : Promise<Ballot[]> => {
        const [, electionId, filter] = queryKey

        const queryParams = getUrlParamsFromFilter(filter as BallotsFilter)

        const res =
            await axios<Ballot[]>(`${moduleUrl}elections/${electionId}/ballots2${queryParams ? `?${queryParams}` : ''}`)

        return res.data
    }

    return useQuery({
        queryKey: ['online-ballots' + (noGlobalPreloader ? '-no-preloader' : ''), electionId, filter],
        queryFn: getOnlineBallots,
        enabled
    })
}