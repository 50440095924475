import { ElectionType } from 'models'

export function getElectionTypeForUrl(electionType: ElectionType, isSecondStage?: boolean): string {
    switch (electionType) {
        case ElectionType.RIDVAN_ELECTION:
            if (isSecondStage)
                return 'second-stage-ridvan-election'

            return 'ridvan-election'
        case ElectionType.LOCAL_DELEGATE_ELECTION:
            return 'local-delegate-elections'
        case ElectionType.BY_ELECTION:
            return 'by-election'
        case ElectionType.OFFICERS_ELECTION:
            return 'officers-elections'
        case ElectionType.NATIONAL_DELEGATE_ELECTION:
            return 'national-delegate-election'
        case ElectionType.RBC_ELECTION:
            return 'rbc-election'
        default:
            return electionType.toLowerCase().replace(/_/g, '-')
    }
}