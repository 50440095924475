import {
    useElectionPeriod,
    useLocalDelegateParticipantsStatistic,
    useParticipants,
    useRidvanParticipantsStatistic
} from './api'
import { getLocalDelegateStatus, isRidvanSecondStage, isRidvanStage } from './utils'

export const ridvan = {
    api: {
        useElectionPeriod,
        useParticipants,
        useRidvanParticipantsStatistic,
        useLocalDelegateParticipantsStatistic
    },
    utils: {
        isRidvanStage,
        getLocalDelegateStatus,
        isRidvanSecondStage
    }
}