import { useElectionsSummary } from 'api'
import { useAbility } from 'hooks'
import { ElectionResultSummary } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import style from './index.module.scss'

type SubElectionResultConfirmProps = {
    electionId: string
    allElectionsIds: string[]
    allElectionsSummary?: { election: number, electionResults: ElectionResultSummary }[]
}

function SubElectionResultConfirm({
    electionId,
    allElectionsIds,
    allElectionsSummary
}: SubElectionResultConfirmProps) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { ability } = useAbility()

    const { data: electionsSummary } = useElectionsSummary(
        { electionIds: allElectionsIds }, !allElectionsSummary
    )

    const [electionSummary, setElectionSummary] = useState<ElectionResultSummary>({
        confirmedBy: '',
        mergedBy: '',
        signedBy: '',
        tiePresent: false,
        comment: '',
        commentedAt: '',
        commentedBy: '',
        breakingTieSkipped: false,
        breakingTieResolved: false,
        tieResolutionComment: ''
    })

    useEffect(() => {
        const elSummary = allElectionsSummary ? allElectionsSummary : electionsSummary
        if (elSummary) {
            const summary = elSummary.find((i) => i?.election.toString() === electionId)

            if (summary) {
                setElectionSummary(summary.electionResults)
            }
        }
    }, [electionsSummary, allElectionsSummary, electionId])

    return (
        <>
            {!!electionSummary.confirmedBy ? (
                <button
                    className={`${style.action} btn-main-secondary-md`}
                    onClick={() =>
                        navigate(`/confirmed-results/${electionId}`, {
                            state: { backButtonTitle: 
                                ability?.can('redirect', 'BackToList') ?
                                t('election:back_to_list') :
                                t('election:back_to_election_board') }
                        })
                    }
                >
                    {t('view_results')}
                </button>
            ) : (
                <button
                    className={`${style.action} ${!!electionSummary.signedBy
                        ? 'btn-main-primary-md' : 'btn-main-secondary-md'
                        }`}
                    onClick={() =>
                        navigate(`/confirm-results/${electionId}`, {
                            state: { backButtonTitle: 
                                ability?.can('redirect', 'BackToList') ?
                                t('election:back_to_list') :
                                t('election:back_to_election_board') }
                        })
                    }
                    disabled={!electionSummary.signedBy}
                >
                    {t('confirm_results')}
                </button>
            )}
        </>
    )
}

export default SubElectionResultConfirm