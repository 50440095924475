import { AppointedTeam, AppointedTeller, LocalDelegateStageElection, SubElectionViewModel, TellerType } from 'models'

export const getTellers = (election: SubElectionViewModel | LocalDelegateStageElection): AppointedTeam => {
    if (!election.tellers?.length) {
        return {
            assistants: [],
            tellers: []
        }
    }

    const sortByName = (a: AppointedTeller, b: AppointedTeller) => {
        if (a.name > b.name)
            return 1
        if (a.name < b.name)
            return -1

        return 0
    }

    const chief = election.tellers.find(t => t.role === TellerType.CHIEF_TELLER)
    const assistants = election.tellers.filter(t => t.role === TellerType.CHIEF_TELLER_ASSISTANT).sort(sortByName)
    const tellers = election.tellers.filter(t => t.role === TellerType.TELLER).sort(sortByName)

    return {
        chief,
        assistants,
        tellers
    }
}