import { ElectionStatusType } from 'models'

export const getRbcStatus = (
    RbcElections: { status: ElectionStatusType }[]
): ElectionStatusType => {
    //if there is at least one 'IN_PROGRESS' unit status, election status should be 'IN PROGRESS'
    if (RbcElections.find((election) => (
        election.status === ElectionStatusType.IN_PROGRESS
    ))) {
        return ElectionStatusType.IN_PROGRESS
    }

    //if there is no 'OPEN' and at least one 'NOT STARTED' unit status, election status should be 'NOT STARTED'
    if (RbcElections.find((election) => (
        election.status === ElectionStatusType.NOT_STARTED
    ))) {
        return ElectionStatusType.NOT_STARTED
    }

    // if all unit statuses are 'CLOSED' or 'CANCELED', election status should be 'CLOSED'
    if (
        RbcElections.filter(
            (election) => (election.status === 'COMPLETED')
        ).length === RbcElections.length
    ) {
        return ElectionStatusType.COMPLETED
    }

    return ElectionStatusType.DRAFT
}