import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { TimeZone } from 'models'

type ElectionVacanciesAndTimezone = {
    id: number
    timeZone: TimeZone
    numberOfVacancies: number
}

type DefineVacanciesAndTimezoneProps = {
    electionPeriod: number
    elections: ElectionVacanciesAndTimezone[]
}

export function useDefineVacanciesAndTimezone() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    async function defineVacanciesAndTimezone({ electionPeriod, elections }: DefineVacanciesAndTimezoneProps) {
        return await axios(moduleUrl
            + `rbc-election-periods/${electionPeriod}/election/define-vacancies-and-timezone`,
            {
                method: 'post', data: { elections }
            })

    }

    return useMutation({
        mutationFn: defineVacanciesAndTimezone,
        mutationKey: ['define-vacancies-and-timezone']
    })
}