import Drawer from 'antd/es/drawer'
import { Can } from 'components/atoms/Can'
import IconWrapper from 'components/atoms/IconWrapper'
import RoleNav from 'components/molecules/RoleNav'
import { administration } from 'features/administration'
import { auth as authFeature } from 'features/auth'
import { ConfirmPopupSettings, useAuth, useConfirmPopup, useHeaderLogoRedirect, usePopup, useSignOut } from 'hooks'
import { ReactComponent as DeleteElection } from 'icons/delete-election.svg'
import { ReactComponent as Dismiss } from 'icons/dismiss.svg'
import { ReactComponent as Eye } from 'icons/eye.svg'
import { ReactComponent as Home } from 'icons/home.svg'
import { ReactComponent as Interrupt } from 'icons/interrupt.svg'
import { ReactComponent as LogoBig } from 'icons/logo-big.svg'
import { ReactComponent as LogoStar } from 'icons/logo-star.svg'
import { ReactComponent as Logo } from 'icons/logo.svg'
import { ReactComponent as Navigation } from 'icons/navigation.svg'
import { ReactComponent as Question } from 'icons/question.svg'
import { ReactComponent as SignOut } from 'icons/sign-out.svg'
import { Role } from 'models'
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import layoutStyles from '../../templates/Layout/index.module.scss'
import InstitutionName from '../InstitutionName'
import styles from './index.module.scss'

type HeaderProps = {
    logoBig?: ReactNode,
    onEndTraining?: (showConfirm: (settings: ConfirmPopupSettings) => void, hide: () => void) => void
}

const Header = ({ logoBig, onEndTraining }: HeaderProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [opened, setOpened] = useState(false)
    const { confirmationCallback } = useHeaderLogoRedirect()
    const logoBigRef = useRef<HTMLElement>(null)
    const { confirmPortal, showConfirm, hide: hideConfirm } = useConfirmPopup()
    const { popupPortal, show, hide } = usePopup()
    const { signOut } = useSignOut(showConfirm)
    const { view } = administration.hooks.useViewUser()
    const { deleteElection } = administration.hooks.useDeleteElection()
    const { auth } = useAuth()

    const canChangeRole = useMemo(() => {
        if (!auth || !auth.role || auth.role === Role.TELLING_STATION) {
            return false
        }

        return true
    }, [auth])

    const { availableRoles, isVoterOnly, isSystemAdmin } = authFeature.hooks.useAvailableRoles(canChangeRole)

    function navigateToDashboard(replace: boolean = true) {
        navigate('/', { replace })
    }

    const showConfirmationPopup = () => {
        showConfirm({
            text: <>{t('would_you_like_to_leave_this_page_without_saving')}</>,
            title: <>{t('leave_current_page')}</>,
            okText: <>{t('leave')}</>,
            cancelText: <>{t('continue_editing')}</>,
            onOk: async () => navigateToDashboard()
        })
    }

    const onLogoClick = useCallback(() => {
        switch (typeof confirmationCallback) {
            case 'boolean':
                if (confirmationCallback) return showConfirmationPopup()

                return navigateToDashboard(true)
            case 'function':
                return confirmationCallback(navigateToDashboard)
        }
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [confirmationCallback])

    useEffect(() => {
        logoBigRef.current?.focus()
    }, [])

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 519 && opened) {
                setOpened(false)
            }
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [opened])

    return (
        <div className={`${styles.header} ${layoutStyles.header} ${isMobile ? styles.mobile : ''}`}>
            {confirmPortal}
            {popupPortal}
            {/* <Can I="view" a="NavigationElement">
                <nav className={styles.burgerNav}>
                    <div className={styles.burger}>
                        <span />
                        <span />
                        <span />
                    </div>
                </nav>
            </Can> */}

            <Can I="view" a="HomeIcon">
                <section className={styles.homeLinkWrapper}>
                    <div onClick={() => onLogoClick()} className={`${styles.homeLink} typo-caption`}>
                        <Home />
                        {t('home')}
                    </div>
                </section>
            </Can>
            <div className={styles.fillAllSpace}>

                <Can I="view" a="Logo">
                    <section className={styles.logoWrapper}>
                        <Logo onClick={() => onLogoClick()} />
                    </section>
                    <section className={styles.logoMobileWrapper}>
                        <LogoStar onClick={() => onLogoClick()} />
                    </section>
                </Can>

                <Can I="view" a="LogoBig">
                    <section
                        tabIndex={0}
                        role={'link'}
                        ref={logoBigRef}
                        aria-label={t('online_balloting_system')}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                onLogoClick()
                            }
                        }}
                        onClick={onLogoClick}
                        className={styles.logoBig}>
                        {logoBig || <LogoBig />}
                    </section>
                </Can>

                <Can I="view" a="Locality">
                    <div className={styles.locality}>
                        <InstitutionName />
                    </div>
                </Can>
            </div>

            <div className={styles.actions}>
                <Can I="view" a="DeleteElectionButton">
                    <section style={{ width: '130px' }} className={styles.helpLinkWrapper}>
                        <span
                            role="button"
                            onClick={() => deleteElection(show, hide)}
                            style={{ whiteSpace: 'nowrap' }}
                            className={`${styles.logoutLink} typo-caption`}>
                            <DeleteElection height="17" />
                            {t('election:delete_election')}
                        </span>
                    </section>
                </Can>
                <Can I="view" a="ViewButton">
                    <section className={styles.helpLinkWrapper}>
                        <span
                            role="button"
                            onClick={() => view(show, hide)}
                            className={`${styles.logoutLink} typo-caption`}>
                            <Eye height="17" />
                            {t('view')}
                        </span>
                    </section>
                </Can>

                <section className={styles.helpLinkWrapper}>
                    <Link
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                e.preventDefault()
                            }
                        }}
                        to="https://helpcenter.obsbahai.org/" target="_blank"
                        className={`${styles.helpLink} typo-caption`}><Question />{t('help')}
                    </Link>
                </section>

                <Can I="view" a="RoleNav">
                    <section className={styles.roleWrapper}>
                        <RoleNav isRoleDisabled={!!confirmationCallback
                            || isVoterOnly(availableRoles)
                            || isSystemAdmin(availableRoles)} />
                    </section>
                </Can>
                {!!onEndTraining &&
                    <Can I="perform" a="EndTraining">
                        <section className={styles.endTrainingWrapper}>
                            <span
                                role="button"
                                onClick={() => onEndTraining(showConfirm, hideConfirm)}
                                className={`${styles.endTraining} typo-caption`}>
                                <Interrupt height="17" width="17" />
                                {t('end_training')}
                            </span>
                        </section>
                    </Can>
                }

                <section className={styles.logoutWrapper}>
                    <span
                        tabIndex={0}
                        role="button"
                        onClick={() => signOut()}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                e.preventDefault()
                                signOut()
                            }
                        }}
                        className={`${styles.logoutLink} typo-caption`}>
                        <SignOut height="17" />
                        {t('log_out')}
                    </span>
                </section>
            </div>

            <div className={styles.burgerMenu}>
                <div className={styles.navigation} tabIndex={0} role="button">
                    {opened
                        ? <Dismiss
                            aria-label={t('close_burger_menu')}
                            height="20" width="20" onClick={() => setOpened(false)} />
                        : <Navigation
                            aria-label={t('burger_menu')}
                            height="20" width="20" onClick={() => setOpened(true)} />
                    }
                </div>

                <Drawer
                    placement="top"
                    rootClassName={styles.mobileActions}
                    height="140px"
                    closable={false}
                    onClose={() => setOpened(false)}
                    open={opened}
                    key="top"
                >
                    <ul>
                        <Link to="https://helpcenter.obsbahai.org/" target="_blank">
                            <li>
                                <IconWrapper><Question height="20" width="20" /></IconWrapper>
                                <span>{t('help')}</span>
                            </li>
                        </Link>
                        <li>
                            <RoleNav asListItem
                                isRoleDisabled={isVoterOnly(availableRoles)} />
                        </li>
                        <li role="button" onClick={() => { navigate('/sign-in') }}>
                            <SignOut height="20" width="20" />
                            <span>{t('log_out')}</span>
                        </li>
                    </ul>
                </Drawer>
            </div>
        </div>
    )
}

export default Header