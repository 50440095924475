import BatchFileDownloader from 'components/molecules/BatchFileDownloader'
import { ReportToLoad } from 'models'
import { ReactNode, useEffect, useState } from 'react'
import { useReportLoader } from './useReportLoader'

export const useBatchReportsLoader = () => {
    const [filesUrls, setFilesUrls] = useState<string[]>()
    const [areReportsLoading, setAreReportsLoading] = useState(false)
    const [reportsToLoad, setReportsToLoad] = useState<ReportToLoad[]>([])
    const [downloaderNode, setDownloaderNode] = useState<ReactNode>()

    const { loadReport, reportIsReady, error } = useReportLoader()

    const loadReports = async (reportsToLoad: ReportToLoad[]) => {
        setReportsToLoad(reportsToLoad)
        if (reportsToLoad.length) {
            setAreReportsLoading(true)
            setFilesUrls([])
        }
    }

    useEffect(() => {
        if (reportsToLoad?.length) {
            loadReport(reportsToLoad[0], (url) => setFilesUrls(current => [...(current ?? []), url]))
        } else {
            setAreReportsLoading(false)
        }
    }, [reportsToLoad])

    useEffect(() => {
        if (reportIsReady || error) {
            setReportsToLoad(current => [...current.slice(1)])
        }
    }, [reportIsReady, error])

    useEffect(() => {
        if (!areReportsLoading) {
            setDownloaderNode(<BatchFileDownloader urls={filesUrls ?? []} />)
        }
    },
        [areReportsLoading, filesUrls])

    return { loadReports, areReportsLoading, error, downloaderNode }
}