import { useQuery } from '@tanstack/react-query'
import { ApiModule } from 'api/api-module'
import { useAxios } from 'api/hooks'
import { getModuleUrl } from 'api/utils'
import { RbcName } from 'models'

export const useRbcNames = (enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getTellingStations = async ()
        : Promise<RbcName[]> => {

        const result = await axios<RbcName[]>(
            `${moduleUrl}institutions/rbc`)

        return result.data
    }

    return useQuery({
        queryKey: ['rbc-names'],
        queryFn: getTellingStations,
        enabled
    })
}
