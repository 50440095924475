
import { Col, Form, Row } from 'antd'
import { useForm } from 'antd/es/form/Form'
import {
    useCancelTieRound,
    useDeleteElectionPeriod, useElection,
    useLocalDelegateElectionPushResult
} from 'api'
import PopupFooter from 'components/atoms/PopupFooter'
import ElectionBadge from 'components/molecules/ElectionBadge'
import LookupSelect from 'components/molecules/LookupSelect'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import { queryClient } from 'config/query-client'
import { electionEditing } from 'features/electionEditing'
import { useElectionTypeMap } from 'hooks'
import { Election, ElectionStatusType, ElectionType, RidvanElectionPeriod, UrlPeriodType } from 'models'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './index.module.scss'

type DeleteElectionProps = {
    onCancel: () => void,
    onSubmit: () => void
}

function DeleteElection({ onCancel, onSubmit }: DeleteElectionProps) {
    const { t } = useTranslation('election')
    const [form] = useForm()
    const { toString } = useElectionTypeMap()
    const deleteButtonRef = useRef<HTMLButtonElement | null>(null)

    const [electionId, setElectionId] = useState('')
    const [selectedItemId, setSelectedItemId] = useState(0)

    const { mutateAsync: cancelTieRound } = useCancelTieRound()
    const { mutateAsync: deleteElectionPeriod } = useDeleteElectionPeriod()

    const { data: election, isFetching: electionFetching } = useElection(electionId, !!electionId)
    const { data: ldePeriod, isFetching: periodFetching } =
        electionEditing.ridvan.api.useElectionPeriod(electionId, !!electionId)
    const { data: rbcPeriod, isFetching: rbcPeriodFetching } =
        electionEditing.rbcElection.api.queries.useElectionPeriod(electionId, !!electionId)
    const { data: periodElection, isFetching: periodElectionFetching } =
        useElection(ldePeriod?.ridvanElectionStage.election.id.toString() || '', !!ldePeriod)
    const { data: ldePushResult } = useLocalDelegateElectionPushResult(ldePeriod?.id.toString() || '', !!ldePeriod)

    const getElectionDisplayInfo = (election: Election) => {
        if (!election) return null

        if (election.status !== ElectionStatusType.IN_PROGRESS && election.status !== ElectionStatusType.NOT_STARTED) {
            return null
        }

        let regionInfo = ''
        if ([ElectionType.RIDVAN_ELECTION, ElectionType.BY_ELECTION, ElectionType.BREAKING_TIE,
        ElectionType.OFFICERS_ELECTION].includes(election.type)) {
            regionInfo = `${toString(election.type)}, ${election.region.locality}, ${election.region.name}`
        }
        if (election.type === ElectionType.NATIONAL_DELEGATE_ELECTION) {
            regionInfo = `${toString(election.type)}, ${election.region.unit}, ${election.region.name}`
        }
        if (election.type === ElectionType.LOCAL_DELEGATE_ELECTION) {
            regionInfo = t('administration:one_of_local_delegate_elections',
                {
                    interpolation: { escapeValue: false },
                    period: `"${election.electionPeriod}"`
                }
            )
        }
        if (election.type === ElectionType.RBC_ELECTION) {
            regionInfo = t('administration:one_of_rbc_delete_the_whole_period_instead',
                {
                    interpolation: { escapeValue: false },
                    period: `"${election.electionPeriod}"`
                }
            )
        }

        return {
            id: election.id,
            name: regionInfo,
            type: election.type,
            disabled: [ElectionType.LOCAL_DELEGATE_ELECTION, ElectionType.RBC_ELECTION].includes(election.type)
        }
    }

    const getLdePeriodDisplayInfo = (period: RidvanElectionPeriod, election: Election) => {
        if (!period ||
            electionEditing.ridvan.utils.isRidvanStage(period)
        ) {
            return null
        }

        const regionInfo = ` ${toString(
            ElectionType.LOCAL_DELEGATE_ELECTION)}, ${election.region.locality}, ${election.region.name}`

        return {
            id: period.id,
            name: regionInfo,
            type: ElectionType.LOCAL_DELEGATE_ELECTION
        }
    }

    const options = useMemo(() => {
        const result = []
        if (election) {

            const electionOption = getElectionDisplayInfo(election)
            if (electionOption) {
                result.push(electionOption)
            }
        }
        if (ldePeriod && periodElection) {
            const ldePeriodOption = getLdePeriodDisplayInfo(ldePeriod, periodElection)
            if (ldePeriodOption) {
                result.push(ldePeriodOption)
            }
        }
        if (rbcPeriod) {
            result.push({
                id: rbcPeriod.id,
                name: toString(ElectionType.RBC_ELECTION),
                type: ElectionType.RBC_ELECTION
            })
        }

        return result
    }, [election, ldePeriod, rbcPeriod, periodElection])

    const onElectionChange = (selectedElection?: any) => {
        if (selectedElection) {
            deleteButtonRef?.current?.focus()
            if (selectedElection.id) {
                setSelectedItemId(selectedElection.id)

                return
            }
        }
        setSelectedItemId(0)
    }

    const updateFilter = (value?: string) => {
        if (!value) {
            setElectionId('')
        } else {
            const trimmedValue = value.trim()
            const num = +trimmedValue
            if (!isNaN(num)) {
                setElectionId(trimmedValue)
            } else {
                setElectionId('')
            }
        }
    }

    const submit = async () => {
        try {
            await form.validateFields()
        } catch {
            return
        }

        const clearCacheAndCallOnSubmit=()=>{
            queryClient.removeQueries(['election'])
            queryClient.removeQueries(['ridvan-election-period'])
            queryClient.removeQueries(['rbc-election-period'])
            onSubmit()
        }

        if (!!election && selectedItemId === election.id) {
            switch (election.type) {
                case ElectionType.BY_ELECTION:
                    await deleteElectionPeriod({
                        electionPeriodId: election.electionPeriod,
                        periodType: UrlPeriodType.ByElectionPeriods
                    })
                    queryClient.invalidateQueries(['report', 'lsa-elections'])
                    break
                case ElectionType.NATIONAL_DELEGATE_ELECTION:
                    await deleteElectionPeriod({
                        electionPeriodId: election.electionPeriod,
                        periodType: UrlPeriodType.NationalDelegateElectionPeriods
                    })
                    queryClient.invalidateQueries(['report', 'national-delegate-elections'])
                    break
                case ElectionType.OFFICERS_ELECTION:
                    await deleteElectionPeriod({
                        electionPeriodId: election.electionPeriod,
                        periodType: UrlPeriodType.OfficersElectionPeriods
                    })
                    queryClient.invalidateQueries(['report', 'officers-elections'])
                    break
                case ElectionType.RIDVAN_ELECTION:
                    await deleteElectionPeriod({
                        electionPeriodId: election.electionPeriod,
                        periodType: UrlPeriodType.RidvanElectionPeriods
                    })
                    queryClient.invalidateQueries(['report', 'lsa-elections'])
                    break
                case ElectionType.BREAKING_TIE:
                    await cancelTieRound({ electionId: election.id.toString() })
                    break
                default:
                    throw new Error('Unsupported election type')
            }
            clearCacheAndCallOnSubmit()
        }
        if (!!ldePeriod && selectedItemId === ldePeriod.id) {
            await deleteElectionPeriod({
                electionPeriodId: ldePeriod.id,
                periodType: UrlPeriodType.RidvanElectionPeriods
            })
            //TODO: Invalidate List of RBC Elections
            clearCacheAndCallOnSubmit()
        }
        if (!!rbcPeriod && selectedItemId === rbcPeriod.id) {
            await deleteElectionPeriod({
                electionPeriodId: rbcPeriod.id,
                periodType: UrlPeriodType.RbcElectionPeriods
            })
            queryClient.invalidateQueries(['report', 'lsa-elections'])
            clearCacheAndCallOnSubmit()
        }
    }

    const locationDetails = () => {
        if (election?.type === ElectionType.RBC_ELECTION)
            return <></>

        if (election?.type === ElectionType.NATIONAL_DELEGATE_ELECTION)
            return <>
                <Row gutter={24}>
                    <Col span={5} className={style.title}>{t('administration:hosting_locality')}:</Col>
                    <Col span={19} className={style.value}>
                        {election.hostingLocality.name}, {election.hostingLocality.code}
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={5} className={style.title}>{t('administration:electoral_unit')}:</Col>
                    <Col span={19} className={style.value}>{election.region.unit}</Col>
                </Row>
            </>

        return <Row gutter={24}>
            <Col span={5} className={style.title}>{t('locality_code')}:</Col>
            <Col span={19} className={style.value}>{election?.region.locality}</Col>
        </Row>
    }

    return (
        <Form
            name="election"
            layout="vertical"
            form={form}>
            <Form.Item
                name="election"
                rules={[{
                    required: true,
                    message: t('please_enter_the_input', { input: t('election_id') })
                }]}
            >
                <LookupSelect
                    noOptionalTooltip
                    allowPaste
                    autoFocus
                    optionRenderFunction={(election) =>
                        <div className={style.election}>
                            <strong className={style.id}>{election.id}</strong>
                            <span className={style.name}>
                                <OptionalTooltip contentWrapperClassName="ellipsis">
                                    {election.name}
                                </OptionalTooltip>
                            </span>
                        </div>
                    }
                    onSearchChange={updateFilter}
                    onChange={onElectionChange}
                    options={options}
                    isFetchingOptions={electionFetching
                        || periodFetching
                        || rbcPeriodFetching
                        || periodElectionFetching}
                    placeholder={t('election_id')}
                />
            </Form.Item>
            {!!election
                && selectedItemId === election.id
                && <div className={style.details}>
                    <Row gutter={24}>
                        <Col span={5} className={style.title}>{t('election_type')}:</Col>
                        <Col span={19} className={style.value}>
                            {election.type === ElectionType.BREAKING_TIE
                                ? (election as any).name
                                : toString(election.type)
                            }
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={5} className={style.title}>
                            {election.type === ElectionType.NATIONAL_DELEGATE_ELECTION
                                ? t('region')
                                : election.type === ElectionType.RBC_ELECTION
                                    ? t('rbc')
                                    : t('locality_name')}:
                        </Col>
                        <Col span={19} className={style.value}>{election.region.name}</Col>
                    </Row>
                    {locationDetails()}
                    <Row gutter={24}>
                        <Col span={5} className={style.title}>{t('administration:election_status')}:</Col>
                        <Col span={19} className={style.value}><ElectionBadge status={election.status} /></Col>
                    </Row>
                </div>
            }
            {!!ldePeriod && !!periodElection
                && selectedItemId === ldePeriod.id
                && <div className={style.details}>
                    <Row gutter={24}>
                        <Col span={5} className={style.title}>{t('election_type')}:</Col>
                        <Col span={19} className={style.value}>{toString(ElectionType.LOCAL_DELEGATE_ELECTION)}</Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={5} className={style.title}>{t('locality_name')}:</Col>
                        <Col span={19} className={style.value}>{periodElection.region.name}</Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={5} className={style.title}>{t('locality_code')}:</Col>
                        <Col span={19} className={style.value}>{periodElection.region.locality}</Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={5} className={style.title}>{t('administration:election_status')}:</Col>
                        <Col span={19} className={style.value}>
                            <ElectionBadge
                                status={electionEditing.ridvan.utils.getLocalDelegateStatus(
                                    ldePeriod.localDelegateElectionStage!.elections.map(e => ({ status: e.status })),
                                    ldePushResult)} />
                        </Col>
                    </Row>
                </div>
            }
            {!!rbcPeriod && selectedItemId === rbcPeriod.id &&
                <div className={style.details}>
                    <Row gutter={24}>
                        <Col span={5} className={style.title}>{t('election_type')}:</Col>
                        <Col span={19} className={style.value}>{toString(ElectionType.RBC_ELECTION)} ({
                            t('administration:includes_elections_of_n_institutions',
                                { n: rbcPeriod.elections?.length })})</Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={5} className={style.title}>{t('administration:election_status')}:</Col>
                        <Col span={19} className={style.value}>
                            <ElectionBadge
                                status={electionEditing.rbcElection.utils.getRbcStatus(
                                    rbcPeriod.elections?.map(e => ({ status: e.status })) || [])} />
                        </Col>
                    </Row>
                </div>}
            <PopupFooter>
                <button
                    onClick={onCancel}
                    className={'btn-main-secondary'}>
                    {t('cancel')}
                </button>
                <button
                    ref={deleteButtonRef}
                    type="submit"
                    onClick={submit}
                    className={'btn-main-primary'}>
                    {t('delete')}
                </button>
            </PopupFooter>
        </Form>
    )
}

export default DeleteElection