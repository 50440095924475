import AdaptiveLayout from 'components/templates/AdaptiveLayout'
import Layout from 'components/templates/Layout'
import { Role } from 'models'
import { lazy } from 'react'
import { createBrowserRouter } from 'react-router-dom'
import RequireAuth from 'routing/RequireAuth'
import { errorHandlingRoutesChildren } from 'routing/error-handling-routes'
import SandboxLogo from '../features/auth/components/SandboxLogo'
import DefaultLayout from './DefaultLayout'
import DefaultRouter from './DefaultRouter'

const SandboxSignIn = lazy(() => import('features/tellingStationSandbox/features/auth/pages/SandboxSignIn'))
const Auth = lazy(() => import('features/auth/pages/Auth'))
const Ballot = lazy(() => import('features/ballotingAssets/pages/Ballot'))

export const tellingStationSandboxRouter = createBrowserRouter([
    {
        path: '/',
        element: <DefaultLayout />,
        children: [
            {
                index: true,
                element: <DefaultRouter />
            }
        ]
    },
    {
        element: <AdaptiveLayout header={<></>} />,
        children: [
            {
                path: '/sign-in',
                element: <SandboxSignIn />
            },
            {
                path: '/auth',
                element: <Auth />
            }
        ]
    },
    {
        element: <RequireAuth allowedRoles={[Role.TELLING_STATION]} />,
        children: [
            {
                element: <DefaultLayout />,
                children: [
                    {
                        path: '/ballot/:ballotId',
                        element: <Ballot />
                    }
                ]
            }
        ]
    },
    {
        element: <Layout headerLogoBig={<SandboxLogo />} />,
        children: errorHandlingRoutesChildren
    }
])