import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { VotingApproach } from 'models'

type OnlineVotersResponse = {
    total: number,
    verification: { id: number, votingApproach: VotingApproach }[]
}

export const useOnlineVoters = (electionId: string, enabled?: boolean, refetchInterval?: number) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.OnlineBallot)

    const getOnlineVoters = async ({ queryKey }: QueryFunctionContext)
        : Promise<OnlineVotersResponse> => {
        const [, electionId] = queryKey

        const result = await axios<OnlineVotersResponse>(
            `${moduleUrl}elections/${electionId}/voters`)

        return { ...result.data, verification: (result.data as any).voters }
    }

    return useQuery({
        queryKey: ['online-voters' + (refetchInterval ? '-with-background-refetch' : ''), electionId],
        queryFn: getOnlineVoters,
        enabled,
        refetchInterval
    })
}