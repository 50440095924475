import SpinnerWrapper from 'components/molecules/SpinnerWrapper'
import { PreloaderContext } from 'context'
import { PropsWithChildren, useState } from 'react'

export const PreloaderProvider = ({ children }: PropsWithChildren) => {
    const [visiblePreloadersCount, setVisiblePreloadersCount] = useState(0)

    return (
        <PreloaderContext.Provider value={{ visiblePreloadersCount, setVisiblePreloadersCount }}>
            <>
                {!!visiblePreloadersCount && <SpinnerWrapper />}
            </>
            {children}
        </PreloaderContext.Provider>
    )
}