import { useQuery } from '@tanstack/react-query'
import { ApiModule } from 'api/api-module'
import { useAxios } from 'api/hooks'
import { getModuleUrl } from 'api/utils'
import { AxiosInstance } from 'axios'

export const getVoteCountingStarted = async (axios: AxiosInstance, id: string) => {
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const res = await axios<boolean>(`${moduleUrl}elections/${id}/vote-counting-started`)

    return res.data
}

export const useVoteCountingStarted = (id: string, enabled?: boolean) => {
    const axios = useAxios()

    return useQuery({
        queryKey: ['vote-counting-started', id],
        queryFn: () => getVoteCountingStarted(axios, id),
        enabled
    })
}