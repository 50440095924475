import { AbilityBuilder } from '@casl/ability'
import { Auth, Role } from 'models'
import { AppAbility } from './ability'

export type ElectionBoardSubject =
    'LocalityName' | 'ChiefTellerActions' | 'Tellers' | 'Stations' | 'Results' | 'BackToList' |
    'ElectionBoard' | 'TellersReportButton' | 'StationBallotsReset' | 'ChangeRole' | 'RollBackTellersProcess'
export function defineElectionBoardAbility(builder: AbilityBuilder<AppAbility>, auth?: Auth) {
    const { can } = builder

    if (auth?.role === Role.NSA_ADMIN
        || auth?.role === Role.SYSTEM_ADMIN) {
        can('view', 'LocalityName')
        can('perform', 'StationBallotsReset')
        can('redirect', 'BackToList')
    }
    if (auth?.role === Role.NSA_ADMIN
        || auth?.role === Role.LSA_OFFICER
        || auth?.role === Role.SYSTEM_ADMIN) {
        can('view-only', 'ChiefTellerActions')
        can('confirm', 'Results')
        can('redirect', 'ElectionBoard')
    }
    if (auth?.role === Role.NSA_ADMIN
        || auth?.role === Role.CHIEF_TELLER
        || auth?.role === Role.CHIEF_TELLER_ASSISTANT
        || auth?.role === Role.SYSTEM_ADMIN) {
        can('edit', 'Stations')
    }

    if (auth?.role === Role.NSA_ADMIN
        || auth?.role === Role.SYSTEM_ADMIN
        || auth?.role === Role.LSA_OFFICER) {
        can('edit-before-vote-counting', 'Tellers')
        can('view', 'TellersReportButton')
    }

    if (auth?.role === Role.LSA_OFFICER) {
        can('perform', 'ChangeRole')
    }

    if (auth?.role === Role.SYSTEM_ADMIN) {
        can('perform', 'RollBackTellersProcess')
    }
}
