import axios from 'axios'
import { isSandbox } from 'utils'

const origin = window.location.origin
const url = new URL(origin)

const usePorts = process.env.NODE_ENV === 'development' ||
    origin.includes('localhost') || !url.host.match(/[a-z]/i)

const protocolAndHost = `${url.protocol}//${url.hostname}`

const getCombinedUrl = (urlModule: string) =>
    `${url.protocol}//${urlModule}.${url.host}/`

export const BASE_URL = usePorts
    ? `${protocolAndHost}:8080/`
    : '/api/'

export const ADMINISTRATION_URL = usePorts
    ? `${protocolAndHost}:8080/`
    : getCombinedUrl('administration')

export const AUTHENTICATION_URL = usePorts
    ? `${protocolAndHost}:8080/`
    : getCombinedUrl('authentication-service')

export const TELLING_STATION_URL = usePorts
    ? `${protocolAndHost}:8082/`
    : getCombinedUrl('telling-station')

export const ONLINE_BALLOT_URL = usePorts
    ? (isSandbox ? `${protocolAndHost}:8080/` : `${protocolAndHost}:8083/`)
    : getCombinedUrl('online-ballot')

export const REPORT_URL = usePorts
    ? (isSandbox ? `${protocolAndHost}:8080/` : `${protocolAndHost}:8084/`)
    : getCombinedUrl('report')

export const TOOLKIT_URL = usePorts
    ? `${protocolAndHost}:8086/`
    : getCombinedUrl('toolkit')

export const NOTIFICATION_URL = usePorts
    ? `${protocolAndHost}:8085/`
    : getCombinedUrl('notification')

export const STARTER_URL = usePorts
    ? `${protocolAndHost}:8080/`
    : getCombinedUrl('starter')

export const BOSA_URL = usePorts
    ? `https://${url.hostname}:8079/`
    : (window as any).__RUNTIME_CONFIG__?.BOSA_LOGIN_URI + '/'

export const obsApi = axios.create({
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
    }
})

export const setTokenToApi = (token: string) => {
    if (token) {
        obsApi.defaults.headers.authorization = `Bearer ${token}`
    }
}