import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

export function useAnnounceRbcElectionPeriod() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    async function announceRbcElectionPeriod(electionPeriod: string) {

        return await axios(moduleUrl
            + `rbc-election-periods/${electionPeriod}/election/announce`,
            {
                method: 'post'
            })
    }

    return useMutation({
        mutationFn: announceRbcElectionPeriod,
        onSuccess: () => {
            //TODO: Invilidate required queries here when Dashboard is ready
        }
    })
}