import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl } from 'api'
import axios from 'axios'
import { useAccessToken } from 'hooks'
import jwt_decode from 'jwt-decode'
import { getCurrentDateForLogging } from 'utils'

type StartViewCommand = {
    bahaId: string,
    isLsa: boolean
}

export function useStartView() {
    const moduleUrl = getModuleUrl(ApiModule.AuthenticationService)
    const { accessToken } = useAccessToken()
    const api = axios.create({
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            'User-Local-Time-and-Time-Zone': getCurrentDateForLogging(),
            'Authorization': `Bearer ${accessToken}`
        },
        withCredentials: true
    })

    async function startView({ bahaId, isLsa }: StartViewCommand) {

        const decoded = jwt_decode<{ utl: string }>(accessToken || 'no-access-token')
        const segments = (decoded.utl || '').split('/')

        const response = await api(`${moduleUrl}${segments.filter(s => !!s).join('/')}`,
            {
                method: 'post',
                data: {
                    member: {
                        memberId: bahaId,
                        isLsaOfficer: isLsa
                    }
                }
            })

        return {
            accessToken: response.headers['access-token']
        }
    }

    return useMutation({
        mutationFn: startView
    })
}