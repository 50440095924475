import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { TimeZone } from 'models'

dayjs.extend(utc)
dayjs.extend(timezone)

export const convertUtcToElectionTimeZone = (dateWithUtcTime: string,
    timeZone: TimeZone,
    format: string = 'MM-DD-YYYY') => dayjs.utc(dateWithUtcTime).tz(timeZone).format(format)