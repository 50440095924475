import { ConfigDateType } from 'models'
import { useTranslation } from 'react-i18next'

export const useConfigDateTypeMap = () => {
    const { t } = useTranslation('configuration')

    return {
        toString: (configDateType: ConfigDateType): string => {
            switch (configDateType) {
                case ConfigDateType.ELECTION_DAY:
                    return t('election_date')
                case ConfigDateType.CERTAIN_DATE:
                    return t('specific_date')
            }
        }
    }

}