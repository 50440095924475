import ManageOfficersElectionMaintenance from './components/ManageOfficersElection/ManageOfficersElections'
import SelectElectionToCreate from './components/SelectElectionToCreate'
import SubElectionResultConfirm from './components/SubElectionResultConfirm'
import { byElection } from './features/byElection'
import { nationalDelegate } from './features/national'
import { rbcElection } from './features/rbcElection'
import { ridvan } from './features/ridvan'

export const electionEditing = {
    components: {
        SelectElectionToCreate,
        ManageOfficersElectionMaintenance,
        SubElectionResultConfirm
    },
    ridvan,
    byElection,
    nationalDelegate,
    rbcElection
}