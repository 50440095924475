import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { RbcElectionPeriod } from 'models'

export const useElectionPeriod = (electionPeriodId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getElectionPeriod = async () => {
        const result = await axios<RbcElectionPeriod>(
            `${moduleUrl}rbc-election-periods/${electionPeriodId}`)

        return result.data
    }

    return useQuery({
        queryKey: ['rbc-election-period', electionPeriodId],
        queryFn: () => getElectionPeriod(),
        enabled
    })
}
