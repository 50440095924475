import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { defaultToastProps } from 'utils'
import { useCopyToClipboard } from './useCopyToClipboard'

export const useCopyToClipboardWithToast = () => {

    const { copy } = useCopyToClipboard()
    const { t } = useTranslation('common')
    const [api, contextHolder] = notification.useNotification()

    const copyWithToast = (text: string, cb?: (text: string) => any) => {
        copy(text, cb)

        api.open({
            ...defaultToastProps,
            message: t('copied_to_clipboard')
        })
    }

    return {
        copy: copyWithToast,
        contextHolder
    }

}